import { TeachingAssignments } from '../../components/teaching-assignments/TeachingAssignments'
import styled from '../../utils/styled'

export const TeachingAssignmentIndexPage = () => {
    return (
        <PageContent>
            <div style={{ height: '100vh', width: '100%', padding: '1em' }}>
                <TeachingAssignments />
            </div>
        </PageContent>
    )
}

const PageContent = styled('article')`
    display: flex;
    column-gap: 20px;
`
