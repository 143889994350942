function isEndTimeProblem(obj) {
    return (obj.type === "endTimeProblem");
}
function endTimeProblem(obj) {
    return {
        endTimeProblem: obj,
        type: "endTimeProblem",
    };
}
function isTeacherConflictProblem(obj) {
    return (obj.type === "teacherConflictProblem");
}
function teacherConflictProblem(obj) {
    return {
        teacherConflictProblem: obj,
        type: "teacherConflictProblem",
    };
}
function isRoomConflictProblem(obj) {
    return (obj.type === "roomConflictProblem");
}
function roomConflictProblem(obj) {
    return {
        roomConflictProblem: obj,
        type: "roomConflictProblem",
    };
}
function isRoomReservedTimeConflictProblem(obj) {
    return (obj.type === "roomReservedTimeConflictProblem");
}
function roomReservedTimeConflictProblem(obj) {
    return {
        roomReservedTimeConflictProblem: obj,
        type: "roomReservedTimeConflictProblem",
    };
}
function isRequiredRoomAttributeProblem(obj) {
    return (obj.type === "requiredRoomAttributeProblem");
}
function requiredRoomAttributeProblem(obj) {
    return {
        requiredRoomAttributeProblem: obj,
        type: "requiredRoomAttributeProblem",
    };
}
function isNoRoomSetProblem(obj) {
    return (obj.type === "noRoomSetProblem");
}
function noRoomSetProblem(obj) {
    return {
        noRoomSetProblem: obj,
        type: "noRoomSetProblem",
    };
}
function isUnscheduledLectureProblem(obj) {
    return (obj.type === "unscheduledLectureProblem");
}
function unscheduledLectureProblem(obj) {
    return {
        unscheduledLectureProblem: obj,
        type: "unscheduledLectureProblem",
    };
}
function isScheduleOverrunProblem(obj) {
    return (obj.type === "scheduleOverrunProblem");
}
function scheduleOverrunProblem(obj) {
    return {
        scheduleOverrunProblem: obj,
        type: "scheduleOverrunProblem",
    };
}
function isSameSubjectMultipleTimesOnSameDayProblem(obj) {
    return (obj.type === "sameSubjectMultipleTimesOnSameDayProblem");
}
function sameSubjectMultipleTimesOnSameDayProblem(obj) {
    return {
        sameSubjectMultipleTimesOnSameDayProblem: obj,
        type: "sameSubjectMultipleTimesOnSameDayProblem",
    };
}
function isSameCourseMultipleTimesOnSameDayProblem(obj) {
    return (obj.type === "sameCourseMultipleTimesOnSameDayProblem");
}
function sameCourseMultipleTimesOnSameDayProblem(obj) {
    return {
        sameCourseMultipleTimesOnSameDayProblem: obj,
        type: "sameCourseMultipleTimesOnSameDayProblem",
    };
}
function isStudentGroupConflictProblem(obj) {
    return (obj.type === "studentGroupConflictProblem");
}
function studentGroupConflictProblem(obj) {
    return {
        studentGroupConflictProblem: obj,
        type: "studentGroupConflictProblem",
    };
}
function isTeacherLunchProblem(obj) {
    return (obj.type === "teacherLunchProblem");
}
function teacherLunchProblem(obj) {
    return {
        teacherLunchProblem: obj,
        type: "teacherLunchProblem",
    };
}
function isStudentGroupLunchProblem(obj) {
    return (obj.type === "studentGroupLunchProblem");
}
function studentGroupLunchProblem(obj) {
    return {
        studentGroupLunchProblem: obj,
        type: "studentGroupLunchProblem",
    };
}
function isTeacherBreakProblem(obj) {
    return (obj.type === "teacherBreakProblem");
}
function teacherBreakProblem(obj) {
    return {
        teacherBreakProblem: obj,
        type: "teacherBreakProblem",
    };
}
function isStudentGroupBreakProblem(obj) {
    return (obj.type === "studentGroupBreakProblem");
}
function studentGroupBreakProblem(obj) {
    return {
        studentGroupBreakProblem: obj,
        type: "studentGroupBreakProblem",
    };
}
function isTeacherReservedTimeConflictProblem(obj) {
    return (obj.type === "teacherReservedTimeConflictProblem");
}
function teacherReservedTimeConflictProblem(obj) {
    return {
        teacherReservedTimeConflictProblem: obj,
        type: "teacherReservedTimeConflictProblem",
    };
}
function isStudentGroupReservedTimeConflictProblem(obj) {
    return (obj.type === "studentGroupReservedTimeConflictProblem");
}
function studentGroupReservedTimeConflictProblem(obj) {
    return {
        studentGroupReservedTimeConflictProblem: obj,
        type: "studentGroupReservedTimeConflictProblem",
    };
}
function isTeacherFrameTimeProblem(obj) {
    return (obj.type === "teacherFrameTimeProblem");
}
function teacherFrameTimeProblem(obj) {
    return {
        teacherFrameTimeProblem: obj,
        type: "teacherFrameTimeProblem",
    };
}
function isLectureDurationProblem(obj) {
    return (obj.type === "lectureDurationProblem");
}
function lectureDurationProblem(obj) {
    return {
        lectureDurationProblem: obj,
        type: "lectureDurationProblem",
    };
}
function isTotalCourseRoundDuration(obj) {
    return (obj.type === "totalCourseRoundDuration");
}
function totalCourseRoundDuration(obj) {
    return {
        totalCourseRoundDuration: obj,
        type: "totalCourseRoundDuration",
    };
}
function visit(obj, visitor) {
    if (isEndTimeProblem(obj)) {
        return visitor.endTimeProblem(obj.endTimeProblem);
    }
    if (isTeacherConflictProblem(obj)) {
        return visitor.teacherConflictProblem(obj.teacherConflictProblem);
    }
    if (isRoomConflictProblem(obj)) {
        return visitor.roomConflictProblem(obj.roomConflictProblem);
    }
    if (isRoomReservedTimeConflictProblem(obj)) {
        return visitor.roomReservedTimeConflictProblem(obj.roomReservedTimeConflictProblem);
    }
    if (isRequiredRoomAttributeProblem(obj)) {
        return visitor.requiredRoomAttributeProblem(obj.requiredRoomAttributeProblem);
    }
    if (isNoRoomSetProblem(obj)) {
        return visitor.noRoomSetProblem(obj.noRoomSetProblem);
    }
    if (isUnscheduledLectureProblem(obj)) {
        return visitor.unscheduledLectureProblem(obj.unscheduledLectureProblem);
    }
    if (isScheduleOverrunProblem(obj)) {
        return visitor.scheduleOverrunProblem(obj.scheduleOverrunProblem);
    }
    if (isSameSubjectMultipleTimesOnSameDayProblem(obj)) {
        return visitor.sameSubjectMultipleTimesOnSameDayProblem(obj.sameSubjectMultipleTimesOnSameDayProblem);
    }
    if (isSameCourseMultipleTimesOnSameDayProblem(obj)) {
        return visitor.sameCourseMultipleTimesOnSameDayProblem(obj.sameCourseMultipleTimesOnSameDayProblem);
    }
    if (isStudentGroupConflictProblem(obj)) {
        return visitor.studentGroupConflictProblem(obj.studentGroupConflictProblem);
    }
    if (isTeacherLunchProblem(obj)) {
        return visitor.teacherLunchProblem(obj.teacherLunchProblem);
    }
    if (isStudentGroupLunchProblem(obj)) {
        return visitor.studentGroupLunchProblem(obj.studentGroupLunchProblem);
    }
    if (isTeacherBreakProblem(obj)) {
        return visitor.teacherBreakProblem(obj.teacherBreakProblem);
    }
    if (isStudentGroupBreakProblem(obj)) {
        return visitor.studentGroupBreakProblem(obj.studentGroupBreakProblem);
    }
    if (isTeacherReservedTimeConflictProblem(obj)) {
        return visitor.teacherReservedTimeConflictProblem(obj.teacherReservedTimeConflictProblem);
    }
    if (isStudentGroupReservedTimeConflictProblem(obj)) {
        return visitor.studentGroupReservedTimeConflictProblem(obj.studentGroupReservedTimeConflictProblem);
    }
    if (isTeacherFrameTimeProblem(obj)) {
        return visitor.teacherFrameTimeProblem(obj.teacherFrameTimeProblem);
    }
    if (isLectureDurationProblem(obj)) {
        return visitor.lectureDurationProblem(obj.lectureDurationProblem);
    }
    if (isTotalCourseRoundDuration(obj)) {
        return visitor.totalCourseRoundDuration(obj.totalCourseRoundDuration);
    }
    return visitor.unknown(obj);
}
export var IProblem = {
    isEndTimeProblem: isEndTimeProblem,
    endTimeProblem: endTimeProblem,
    isTeacherConflictProblem: isTeacherConflictProblem,
    teacherConflictProblem: teacherConflictProblem,
    isRoomConflictProblem: isRoomConflictProblem,
    roomConflictProblem: roomConflictProblem,
    isRoomReservedTimeConflictProblem: isRoomReservedTimeConflictProblem,
    roomReservedTimeConflictProblem: roomReservedTimeConflictProblem,
    isRequiredRoomAttributeProblem: isRequiredRoomAttributeProblem,
    requiredRoomAttributeProblem: requiredRoomAttributeProblem,
    isNoRoomSetProblem: isNoRoomSetProblem,
    noRoomSetProblem: noRoomSetProblem,
    isUnscheduledLectureProblem: isUnscheduledLectureProblem,
    unscheduledLectureProblem: unscheduledLectureProblem,
    isScheduleOverrunProblem: isScheduleOverrunProblem,
    scheduleOverrunProblem: scheduleOverrunProblem,
    isSameSubjectMultipleTimesOnSameDayProblem: isSameSubjectMultipleTimesOnSameDayProblem,
    sameSubjectMultipleTimesOnSameDayProblem: sameSubjectMultipleTimesOnSameDayProblem,
    isSameCourseMultipleTimesOnSameDayProblem: isSameCourseMultipleTimesOnSameDayProblem,
    sameCourseMultipleTimesOnSameDayProblem: sameCourseMultipleTimesOnSameDayProblem,
    isStudentGroupConflictProblem: isStudentGroupConflictProblem,
    studentGroupConflictProblem: studentGroupConflictProblem,
    isTeacherLunchProblem: isTeacherLunchProblem,
    teacherLunchProblem: teacherLunchProblem,
    isStudentGroupLunchProblem: isStudentGroupLunchProblem,
    studentGroupLunchProblem: studentGroupLunchProblem,
    isTeacherBreakProblem: isTeacherBreakProblem,
    teacherBreakProblem: teacherBreakProblem,
    isStudentGroupBreakProblem: isStudentGroupBreakProblem,
    studentGroupBreakProblem: studentGroupBreakProblem,
    isTeacherReservedTimeConflictProblem: isTeacherReservedTimeConflictProblem,
    teacherReservedTimeConflictProblem: teacherReservedTimeConflictProblem,
    isStudentGroupReservedTimeConflictProblem: isStudentGroupReservedTimeConflictProblem,
    studentGroupReservedTimeConflictProblem: studentGroupReservedTimeConflictProblem,
    isTeacherFrameTimeProblem: isTeacherFrameTimeProblem,
    teacherFrameTimeProblem: teacherFrameTimeProblem,
    isLectureDurationProblem: isLectureDurationProblem,
    lectureDurationProblem: lectureDurationProblem,
    isTotalCourseRoundDuration: isTotalCourseRoundDuration,
    totalCourseRoundDuration: totalCourseRoundDuration,
    visit: visit
};
