import type { ScheduleAccessor } from '../../../../schedule-access/scheduleAccessWrappers'
import type { ScheduleSelector } from '../../../../store/scheduleselector/types'
import { visitScheduleSelector } from '../../../../store/scheduleselector/types'
import type { ScheduleEvent } from '../ScheduleEvent'

export function hasNonEmptyIntersection<E>(l1: E[], l2: E[]): boolean {
    return l1.some((n1) => l2.includes(n1))
}

export const isEventAmongSelectedSchedules = (
    schedule: ScheduleAccessor,
    event: ScheduleEvent,
    selectors: ScheduleSelector[]
) => selectors.some((selector) => isLectureInSelectedSchedule(schedule, event, selector))

const isLectureInSelectedSchedule = (schedule: ScheduleAccessor, event: ScheduleEvent, selector: ScheduleSelector) =>
    visitScheduleSelector(selector, {
        studentGroupSelector: (sgss) => event.isRelatedToStudentGroup(schedule.findStudentGroup(sgss.studentGroupId)),
        teacherSelector: (ts) => event.isRelatedToTeacher(schedule.findTeacher(ts.teacherId)),
        courseRoundSelector: (s) => event.isRelatedToCourseRound(schedule.findCourseRound(s.courseRoundId)),
        roomSelector: (s) => event.isRelatedToRoom(schedule.findRoom(s.roomId)),
        allSelector: () => true
    })
