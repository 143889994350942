import { FilterDropdownProps } from 'antd/es/table/interface'
import { EnumFilterDropdown } from '../../YesNoFilterDropdown'
import { WEEKDAYS } from '../../../utils/DayAndTimeUtil'
import { useNameOfDay } from '../../../utils/weekUtil'

export const DayOfWeekFilterDropdown = (props: FilterDropdownProps) => {
    const { getDayName } = useNameOfDay()
    return (
        <EnumFilterDropdown
            {...props}
            allFilters={[
                {
                    value: 'unscheduled',
                    text: <span style={{ fontStyle: 'italic' }}>Ej schemalagd</span>
                },
                ...WEEKDAYS.map((dayOfWeek) => ({ value: dayOfWeek, text: getDayName(dayOfWeek) }))
            ]}
        />
    )
}
