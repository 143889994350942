import type { ActionType } from 'typesafe-actions'
import { action } from 'typesafe-actions'
import type * as autoSchedulerActions from './actions'
import { AutoSchedulerActionTypes, AutoSchedulerRunningState } from './types'
import { ScheduleId } from '../../commonTypes'

export const setAutoSchedulerState = (state: AutoSchedulerRunningState) =>
    action(AutoSchedulerActionTypes.SET_AUTO_SCHEDULER_STATE, state)

export const handleLocallyTriggeredAutoSchedulerStateUpdate = (isRunning: boolean) =>
    action(AutoSchedulerActionTypes.HANDLE_LOCALLY_TRIGGERED_AUTO_SCHEDULER_STATE_UPDATE, isRunning)

export const subscribe = (scheduleId: ScheduleId) => action(AutoSchedulerActionTypes.SUBSCRIBE, scheduleId)
export const unsubscribe = (scheduleId: ScheduleId) => action(AutoSchedulerActionTypes.UNSUBSCRIBE, scheduleId)

export type AutoSchedulerAction = ActionType<typeof autoSchedulerActions>
