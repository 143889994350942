import { message } from 'antd'
import { toTranslate } from '../../utils/miscUtil'
import styled from '../../utils/styled'
import { useDevModeState } from '../../store/devmode/hooks'
import { useDispatch } from 'react-redux'
import { activateDevMode, deactivateDevMode } from '../../store/devmode/actions'
import { CLICK_TIMEOUT, CLICKS_TO_TOGGLE_DEV_MODE } from './constants'
import { useRapidCounterState } from './hooks'

type MeitnerLogoProps = {
    isCollapsed: boolean
}

export const MeitnerLogo = (props: MeitnerLogoProps) => {
    const [incClickCounter, resetClickCounter] = useRapidCounterState(CLICK_TIMEOUT)
    const dispatch = useDispatch()
    const devMode = useDevModeState()

    const handleClick = () => {
        const newClickCount = incClickCounter()
        if (newClickCount >= CLICKS_TO_TOGGLE_DEV_MODE) {
            const newDevMode = !devMode
            dispatch(newDevMode ? activateDevMode() : deactivateDevMode())
            message.info(`Utvecklarläge ${newDevMode ? 'aktiverat' : 'inaktiverat'}`)
            resetClickCounter()
        }
    }

    return <Logo onClick={handleClick}>{props.isCollapsed ? <MeitnerSymbol /> : <AppIcon />}</Logo>
}

const Logo = styled.div`
    margin: 2em;
`

const MeitnerSymbol = () => (
    <svg width="24" height="25" viewBox="0 0 400 418" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>Meitner</title>
        <path
            d="M379.294 2.75222L200 136.87V247.772L323.608 155.262V374.242L392.392 322.831C394.714 321.146 396.611 318.944 397.932 316.398C399.252 313.851 399.961 311.032 400 308.164V12.5169C399.95 10.1388 399.238 7.82163 397.943 5.82613C396.649 3.83063 394.823 2.23591 392.672 1.2213C390.52 0.2067 388.128 -0.187544 385.765 0.0829087C383.402 0.353361 381.161 1.27778 379.294 2.75222V2.75222Z"
            fill="url(#paint0_linear)"
        />
        <path
            d="M200 247.772V334.046L254.902 292.792V409.772C254.972 411.249 255.445 412.68 256.268 413.908C257.092 415.137 258.236 416.117 259.576 416.742C260.917 417.368 262.402 417.616 263.873 417.458C265.344 417.301 266.744 416.745 267.922 415.85L323.608 374.243V155.262L200 247.772Z"
            fill="#2F0C66"
        />
        <path
            d="M0 12.5168V308.164C0.0151864 311.02 0.69664 313.833 1.99015 316.379C3.28366 318.925 5.15352 321.134 7.45098 322.83L76.1961 374.242V155.262L200 247.772V136.87L20.5098 2.75207C18.642 1.33351 16.42 0.456105 14.0869 0.215887C11.7538 -0.0243304 9.39964 0.381898 7.28199 1.39011C5.16434 2.39832 3.36485 3.96966 2.08048 5.93214C0.796106 7.89462 0.076355 10.1726 0 12.5168H0Z"
            fill="url(#paint1_linear)"
        />
        <path
            d="M76.1934 155.262V374.399L131.84 415.85C133.021 416.797 134.441 417.397 135.943 417.582C137.445 417.768 138.968 417.532 140.344 416.901C141.719 416.271 142.892 415.27 143.731 414.011C144.57 412.752 145.042 411.284 145.095 409.772V292.792L199.997 333.89V247.615L76.1934 155.262Z"
            fill="#160744"
        />
        <defs>
            <linearGradient
                id="paint0_linear"
                x1="-158.314"
                y1="259.497"
                x2="918"
                y2="107.889"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.25" stopColor="#73A0F8" />
                <stop offset="0.6" stopColor="#FF63F8" />
                <stop offset="0.79" stopColor="#931EDF" />
                <stop offset="0.88" stopColor="#6400D4" />
            </linearGradient>
            <linearGradient
                id="paint1_linear"
                x1="-62.6275"
                y1="-21.0911"
                x2="376.078"
                y2="643.536"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.15" stopColor="#6AC7FF" />
                <stop offset="0.22" stopColor="#6CB8FC" />
                <stop offset="0.35" stopColor="#7190F5" />
                <stop offset="0.52" stopColor="#7950EA" />
                <stop offset="0.72" stopColor="#8300DC" />
            </linearGradient>
        </defs>
    </svg>
)

const AppIcon = () => (
    <svg viewBox="0 0 351 107" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>{toTranslate('App icon')}</title>
        <g clipPath="url(#clip0)">
            <path
                d="m 171.441,26.0701 c -1.893,-0.0325 -3.763,0.4111 -5.44,1.29 -1.532,0.8481 -2.81,2.0912 -3.7,3.6 0,0.06 -0.3,0.54 -0.54,0.94 -0.24,0.4 -0.42,0.45 -0.76,-0.24 -0.938,-1.7256 -2.288,-3.1925 -3.93,-4.27 -1.728,-0.9086 -3.658,-1.3629 -5.61,-1.32 -1.747,-0.0987 -3.488,0.2655 -5.049,1.0556 -1.561,0.7902 -2.886,1.9783 -3.841,3.4444 -0.36,0.6 -0.61,1.1 -0.61,0.49 v -3.52 c 0,-0.2449 -0.096,-0.48 -0.269,-0.6541 -0.172,-0.1741 -0.406,-0.2733 -0.651,-0.2759 h -8.64 c -0.122,0 -0.242,0.0241 -0.354,0.071 -0.113,0.0468 -0.214,0.1155 -0.3,0.202 -0.086,0.0865 -0.153,0.1891 -0.199,0.3018 -0.045,0.1128 -0.068,0.2335 -0.067,0.3552 0,0.75 0,1.66 0,2.41 -0.002,0.0948 0.028,0.1875 0.087,0.2622 0.058,0.0748 0.141,0.127 0.233,0.1478 0.57,0.12 1.71,0.59 1.71,2.55 v 26.39 c 0,0.2466 0.098,0.4832 0.273,0.6576 0.174,0.1744 0.41,0.2724 0.657,0.2724 h 6.61 c 0.245,-0.0027 0.479,-0.1018 0.651,-0.2759 0.173,-0.1741 0.269,-0.4092 0.269,-0.6541 v -20.73 c 0.1,-1.5268 0.78,-2.9577 1.9,-4 1.106,-0.9617 2.536,-1.4659 4,-1.41 0.797,-0.0245 1.589,0.1257 2.322,0.4401 0.732,0.3144 1.387,0.7854 1.918,1.3799 1.111,1.3075 1.69,2.9854 1.62,4.7 v 19.62 c 0,0.2449 0.097,0.48 0.269,0.6541 0.172,0.1741 0.406,0.2732 0.651,0.2759 h 6.54 c 0.247,0 0.483,-0.098 0.658,-0.2724 0.174,-0.1744 0.272,-0.411 0.272,-0.6576 v -19.66 c -0.078,-1.7067 0.502,-3.3783 1.62,-4.67 0.536,-0.5828 1.192,-1.0433 1.921,-1.3504 0.73,-0.3072 1.518,-0.4537 2.309,-0.4296 0.784,-0.0297 1.566,0.1155 2.287,0.4251 0.721,0.3096 1.364,0.7759 1.883,1.3649 1.079,1.3335 1.63,3.0167 1.55,4.73 v 19.59 c 0,0.2449 0.097,0.48 0.269,0.6541 0.172,0.1741 0.406,0.2732 0.651,0.2759 h 6.61 c 0.247,0 0.483,-0.098 0.658,-0.2724 0.174,-0.1744 0.272,-0.411 0.272,-0.6576 v -21.68 c 0,-3.66 -1,-6.54 -2.83,-8.54 -1.024,-1.0326 -2.257,-1.8351 -3.616,-2.3541 -1.358,-0.519 -2.812,-0.7426 -4.264,-0.6559 z"
                fill="#160744"
                id="path4"
            />
            <path
                d="m 228.751,26.6101 h -6.6 c -0.513,0 -0.93,0.4164 -0.93,0.93 v 31.75 c 0,0.5136 0.417,0.93 0.93,0.93 h 6.6 c 0.514,0 0.93,-0.4164 0.93,-0.93 v -31.75 c 0,-0.5136 -0.416,-0.93 -0.93,-0.93 z"
                fill="#160744"
                id="path6"
            />
            <path
                d="m 217.001,45.0301 c -0.09,-1.65 -0.22,-3.19 -0.38,-4.58 -0.75,-4.57 -2.39,-8.17 -4.88,-10.69 -2.49,-2.52 -5.88,-3.75 -10.11,-3.75 -3.361,-0.0814 -6.654,0.9485 -9.37,2.93 -2.589,1.9003 -4.446,4.633 -5.26,7.74 -0.523,2.1158 -0.765,4.2911 -0.72,6.47 -0.008,2.0692 0.258,4.1305 0.79,6.13 0.765,3.3301 2.637,6.3022 5.31,8.43 2.892,2.1212 6.417,3.1999 10,3.06 2.539,0.0224 5.053,-0.5029 7.37,-1.54 2.069,-0.9117 3.904,-2.2791 5.37,-4 0.081,-0.0836 0.143,-0.1836 0.181,-0.2932 0.039,-0.1096 0.053,-0.2262 0.043,-0.3419 -0.01,-0.1158 -0.046,-0.2279 -0.103,-0.3288 -0.058,-0.1009 -0.137,-0.1883 -0.231,-0.2561 l -3.67,-3.52 c -0.067,-0.071 -0.149,-0.1255 -0.241,-0.1592 -0.091,-0.0337 -0.189,-0.0457 -0.286,-0.035 -0.097,0.0107 -0.19,0.0437 -0.272,0.0965 -0.082,0.0528 -0.151,0.1239 -0.201,0.2077 -0.886,1.0084 -1.979,1.815 -3.204,2.3653 -1.225,0.5503 -2.553,0.8315 -3.896,0.8247 -1.773,0.052 -3.53,-0.3545 -5.1,-1.18 -1.392,-0.7392 -2.437,-1.9964 -2.91,-3.5 -0.222,-0.6176 -0.373,-1.2584 -0.45,-1.91 l -0.12,-0.78 c -0.08,-0.49 0,-0.48 0.48,-0.49 h 21.18 c 0.085,0.0084 0.171,-0.0011 0.252,-0.028 0.081,-0.0269 0.156,-0.0705 0.219,-0.128 0.063,-0.0575 0.113,-0.1276 0.148,-0.2058 0.034,-0.0782 0.052,-0.1628 0.051,-0.2482 0,0 0.01,-0.24 0.01,-0.29 z m -9.14,-4.73 h -12.81 c -0.17,0 -0.29,-0.09 -0.24,-0.35 l 0.07,-0.37 c 0.104,-0.6945 0.261,-1.3799 0.47,-2.05 0.363,-1.2789 1.132,-2.4053 2.19,-3.21 1.2,-0.7612 2.596,-1.1562 4.017,-1.1366 1.42,0.0195 2.805,0.4527 3.983,1.2466 1.087,0.8573 1.881,2.0316 2.27,3.36 0.19,0.6128 0.294,1.2488 0.31,1.89 0,0 0,0.31 0,0.35 0.04,0.2 -0.12,0.27 -0.3,0.27 z"
                fill="#160744"
                id="path8"
            />
            <path
                d="m 321.64,45.03 c -0.09,-1.65 -0.22,-3.19 -0.38,-4.58 -0.74,-4.57 -2.39,-8.17 -4.88,-10.69 -2.49,-2.52 -5.87,-3.75 -10.11,-3.75 -3.361,-0.0783 -6.653,0.9513 -9.37,2.93 -2.584,1.8994 -4.432,4.6339 -5.23,7.74 -0.527,2.1153 -0.772,4.2907 -0.73,6.47 -0.009,2.0693 0.257,4.1305 0.79,6.13 0.768,3.3312 2.643,6.3033 5.32,8.43 2.889,2.1248 6.416,3.2039 10,3.06 2.538,0.0224 5.052,-0.5028 7.37,-1.54 2.07,-0.9139 3.908,-2.2809 5.38,-4 0.08,-0.0843 0.141,-0.1848 0.179,-0.2948 0.038,-0.11 0.051,-0.2269 0.04,-0.3426 -0.012,-0.1158 -0.048,-0.2277 -0.106,-0.3283 -0.059,-0.1005 -0.138,-0.1872 -0.233,-0.2543 L 316,50.49 c -0.26,-0.27 -0.64,-0.32 -1,0.11 -0.889,1.0082 -1.984,1.8145 -3.21,2.3647 -1.226,0.5502 -2.556,0.8316 -3.9,0.8253 -1.774,0.052 -3.53,-0.3544 -5.1,-1.18 -1.392,-0.7391 -2.438,-1.9964 -2.91,-3.5 -0.222,-0.6176 -0.373,-1.2583 -0.45,-1.91 l -0.11,-0.78 c -0.09,-0.49 0.05,-0.48 0.47,-0.49 H 321 c 0.085,0.0101 0.171,0.0017 0.253,-0.0246 0.082,-0.0262 0.157,-0.0698 0.221,-0.1276 0.063,-0.0578 0.114,-0.1286 0.147,-0.2076 0.034,-0.079 0.051,-0.1643 0.049,-0.2502 0,0 -0.02,-0.24 -0.03,-0.29 z m -9.13,-4.73 h -12.78 c -0.16,0 -0.29,-0.09 -0.24,-0.35 l 0.07,-0.37 c 0.103,-0.6944 0.26,-1.3798 0.47,-2.05 0.363,-1.2789 1.131,-2.4053 2.19,-3.21 1.2,-0.7612 2.595,-1.1561 4.016,-1.1366 1.421,0.0195 2.805,0.4527 3.984,1.2466 0.783,0.6373 1.419,1.4364 1.864,2.3426 0.446,0.9062 0.69,1.898 0.716,2.9074 0,0 0,0.31 0,0.35 0.04,0.2 -0.07,0.27 -0.29,0.27 z"
                fill="#160744"
                id="path10"
            />
            <path
                d="m 345.821,26.9601 c -0.323,-0.1652 -0.657,-0.3089 -1,-0.43 -0.818,-0.2754 -1.677,-0.4073 -2.54,-0.39 -1.662,0.0282 -3.284,0.5085 -4.694,1.3894 -1.409,0.881 -2.552,2.1291 -3.306,3.6106 -0.12,0.23 -0.28,0.15 -0.28,0 0,-0.41 0,-2.38 0.05,-3.57 0.002,-0.1225 -0.021,-0.2441 -0.068,-0.3575 -0.046,-0.1135 -0.114,-0.2165 -0.201,-0.3031 -0.087,-0.0867 -0.19,-0.1551 -0.303,-0.2014 -0.114,-0.0462 -0.235,-0.0694 -0.358,-0.068 h -6.64 c -0.246,0 -0.483,0.0979 -0.657,0.2723 -0.175,0.1745 -0.273,0.411 -0.273,0.6577 V 59.3 c 0,0.2467 0.098,0.4832 0.273,0.6577 0.174,0.1744 0.411,0.2723 0.657,0.2723 h 6.61 c 0.245,-0.0026 0.479,-0.1017 0.651,-0.2759 0.173,-0.1741 0.269,-0.4091 0.269,-0.6541 V 41.4901 c -0.032,-0.8523 0.116,-1.7018 0.435,-2.4929 0.318,-0.791 0.801,-1.5057 1.415,-2.0971 1.648,-1.4567 3.805,-2.2011 6,-2.07 h 0.07 c 0.673,0.0424 1.341,0.1327 2,0.27 0.086,0.0315 0.178,0.0424 0.269,0.0317 0.091,-0.0107 0.178,-0.0427 0.254,-0.0934 0.076,-0.0507 0.139,-0.1186 0.183,-0.1983 0.045,-0.0796 0.07,-0.1687 0.074,-0.26 0.06,-0.3 1.29,-7 1.29,-7 0.07,-0.27 0.11,-0.48 -0.18,-0.62 z"
                fill="#160744"
                id="path12"
            />
            <path
                d="m 252.22,26.5501 -7,0.06 v -7.9 c 0.002,-0.0608 -0.009,-0.1212 -0.032,-0.1776 -0.022,-0.0565 -0.056,-0.1077 -0.099,-0.1507 -0.043,-0.043 -0.094,-0.0768 -0.151,-0.0995 -0.056,-0.0226 -0.117,-0.0336 -0.178,-0.0322 h -4.38 c -0.101,0.0042 -0.198,0.0418 -0.276,0.1069 -0.078,0.0651 -0.132,0.1541 -0.154,0.2531 -0.244,1.8352 -0.869,3.5992 -1.833,5.1797 -0.964,1.5806 -2.247,2.9429 -3.767,4.0003 -0.054,0.0382 -0.099,0.089 -0.131,0.1482 -0.031,0.0591 -0.048,0.1249 -0.049,0.1918 v 4.48 c 0,0.122 0.049,0.239 0.135,0.3253 0.086,0.0863 0.203,0.1347 0.325,0.1347 h 2.63 v 19.19 c 0,5.09 4.12,8.38 10.5,8.38 0.78,0 2.22,-0.08 3.88,-0.17 0.36,0 0.45,-0.23 0.45,-0.54 v -6.08 c 0,-0.122 -0.048,-0.239 -0.134,-0.3253 -0.087,-0.0862 -0.204,-0.1347 -0.326,-0.1347 h -2 c -0.61,0.0505 -1.224,-0.0325 -1.799,-0.2432 -0.575,-0.2107 -1.097,-0.544 -1.531,-0.9768 -0.748,-1.0516 -1.102,-2.3333 -1,-3.62 v -15.47 h 7 c 0.125,0 0.245,-0.0495 0.333,-0.1376 0.088,-0.0882 0.137,-0.2077 0.137,-0.3324 v -5.6 c 0.002,-0.0688 -0.012,-0.137 -0.041,-0.1996 -0.028,-0.0626 -0.071,-0.118 -0.123,-0.1621 -0.053,-0.0442 -0.115,-0.0759 -0.182,-0.0929 -0.066,-0.017 -0.136,-0.0189 -0.204,-0.0054 z"
                fill="#160744"
                id="path14"
            />
            <path
                d="m 275.489,26.0701 c -1.804,-0.0826 -3.594,0.354 -5.157,1.2579 -1.563,0.904 -2.835,2.2374 -3.663,3.8421 -0.16,0.24 -0.27,0.13 -0.27,0 v -3.63 c 0,-0.245 -0.097,-0.48 -0.269,-0.6541 -0.172,-0.1742 -0.406,-0.2733 -0.651,-0.2759 h -6.61 c -0.247,0 -0.483,0.0979 -0.658,0.2723 -0.174,0.1745 -0.272,0.411 -0.272,0.6577 v 31.76 c 0,0.2466 0.098,0.4832 0.272,0.6576 0.175,0.1744 0.411,0.2724 0.658,0.2724 h 6.61 c 0.245,-0.0027 0.479,-0.1018 0.651,-0.2759 0.172,-0.1742 0.269,-0.4092 0.269,-0.6541 v -19.52 c -0.068,-1.7387 0.538,-3.4364 1.69,-4.74 0.562,-0.6083 1.249,-1.0884 2.013,-1.4076 0.765,-0.3193 1.589,-0.4702 2.417,-0.4424 0.822,-0.0342 1.642,0.115 2.399,0.4367 0.758,0.3217 1.434,0.8079 1.981,1.4233 1.105,1.3221 1.679,3.0081 1.61,4.73 v 19.52 c 0,0.2466 0.098,0.4832 0.272,0.6576 0.175,0.1744 0.411,0.2724 0.658,0.2724 h 6.56 c 0.247,0 0.483,-0.098 0.658,-0.2724 0.174,-0.1744 0.272,-0.411 0.272,-0.6576 v -21.48 c 0.08,-1.5717 -0.157,-3.1434 -0.699,-4.6211 -0.541,-1.4777 -1.375,-2.831 -2.451,-3.9789 -1.098,-1.0565 -2.399,-1.8802 -3.823,-2.4215 -1.425,-0.5413 -2.944,-0.7891 -4.467,-0.7285 z"
                fill="#160744"
                id="path16"
            />
            <path
                d="m 225.45,21.6 c 2.651,0 4.8,-2.149 4.8,-4.8 0,-2.651 -2.149,-4.8 -4.8,-4.8 -2.651,0 -4.8,2.149 -4.8,4.8 0,2.651 2.149,4.8 4.8,4.8 z"
                fill="#160744"
                id="path18"
            />
            <path
                d="M96.72 0.659945L51 34.8599V63.1399L82.52 39.5499V95.3899L100.06 82.2799C100.652 81.8505 101.136 81.2888 101.473 80.6395C101.809 79.9902 101.99 79.2713 102 78.5399V3.14995C101.987 2.54351 101.806 1.95264 101.476 1.44379C101.145 0.934941 100.68 0.528287 100.131 0.269563C99.5826 0.0108385 98.9727 -0.0896938 98.3701 -0.0207284C97.7674 0.048237 97.196 0.283965 96.72 0.659945Z"
                fill="url(#paint0_linear)"
                id="path20"
            />
            <path
                d="M51 63.1399V85.1399L65 74.6199V104.45C65.0179 104.827 65.1384 105.191 65.3485 105.505C65.5585 105.818 65.8502 106.068 66.192 106.227C66.5338 106.387 66.9126 106.45 67.2877 106.41C67.6627 106.37 68.0197 106.228 68.32 106L82.52 95.3899V39.5499L51 63.1399Z"
                fill="#2F0C66"
                id="path22"
            />
            <path
                d="M0 3.14997V78.54C0.00387253 79.2682 0.177643 79.9855 0.507488 80.6348C0.837332 81.284 1.31415 81.8474 1.9 82.28L19.43 95.39V39.55L51 63.14V34.86L5.23 0.659966C4.75371 0.298233 4.1871 0.0744944 3.59216 0.013239C2.99723 -0.0480165 2.39691 0.0555717 1.85691 0.312666C1.31691 0.56976 0.858036 0.970451 0.530522 1.47088C0.203007 1.97132 0.0194705 2.5522 0 3.14997Z"
                fill="url(#paint1_linear)"
                id="path24"
            />
            <path
                d="M19.4297 39.5499V95.4299L33.6197 106C33.9207 106.241 34.2829 106.394 34.6658 106.442C35.0487 106.489 35.4373 106.429 35.788 106.268C36.1387 106.107 36.4378 105.852 36.6518 105.531C36.8657 105.21 36.9862 104.836 36.9997 104.45V74.6199L50.9997 85.0999V63.0999L19.4297 39.5499Z"
                fill="#160744"
                id="path26"
            />
            <path
                d="m 226.47932,73.505966 v 3.078125 q -1.79688,-0.859375 -3.39063,-1.28125 -1.59375,-0.421875 -3.07812,-0.421875 -2.57813,0 -3.98438,1 -1.39062,1 -1.39062,2.84375 0,1.546875 0.92187,2.34375 0.9375,0.78125 3.53125,1.265625 l 1.90625,0.390625 q 3.53125,0.671875 5.20313,2.375 1.6875,1.6875 1.6875,4.53125 0,3.390625 -2.28125,5.140625 -2.26563,1.75 -6.65625,1.75 -1.65625,0 -3.53125,-0.375 -1.85938,-0.375 -3.85938,-1.109375 v -3.25 q 1.92188,1.078125 3.76563,1.625 1.84375,0.546875 3.625,0.546875 2.70312,0 4.17187,-1.0625 1.46875,-1.0625 1.46875,-3.03125 0,-1.71875 -1.0625,-2.6875 -1.04687,-0.96875 -3.45312,-1.453125 l -1.92188,-0.375 q -3.53125,-0.703125 -5.10937,-2.203125 -1.57813,-1.5 -1.57813,-4.171875 0,-3.09375 2.17188,-4.875 2.1875,-1.78125 6.01562,-1.78125 1.64063,0 3.34375,0.296875 1.70313,0.296875 3.48438,0.890625 z"
                fill="#2F0C66"
                id="path24572"
            />
            <path
                d="m 244.27619,79.240341 v 2.6875 q -1.21875,-0.671875 -2.45312,-1 -1.21875,-0.34375 -2.46875,-0.34375 -2.79688,0 -4.34375,1.78125 -1.54688,1.765625 -1.54688,4.96875 0,3.203125 1.54688,4.984375 1.54687,1.765625 4.34375,1.765625 1.25,0 2.46875,-0.328125 1.23437,-0.34375 2.45312,-1.015625 v 2.65625 q -1.20312,0.5625 -2.5,0.84375 -1.28125,0.28125 -2.73437,0.28125 -3.95313,0 -6.28125,-2.484375 -2.32813,-2.484375 -2.32813,-6.703125 0,-4.28125 2.34375,-6.734375 2.35938,-2.453125 6.45313,-2.453125 1.32812,0 2.59375,0.28125 1.26562,0.265625 2.45312,0.8125 z"
                fill="#2F0C66"
                id="path24574"
            />
            <path
                d="m 262.82307,85.505966 v 10.5625 h -2.875 v -10.46875 q 0,-2.484375 -0.96875,-3.71875 -0.96875,-1.234375 -2.90625,-1.234375 -2.32813,0 -3.67188,1.484375 -1.34375,1.484375 -1.34375,4.046875 v 9.890625 h -2.89062 v -24.3125 h 2.89062 v 9.53125 q 1.03125,-1.578125 2.42188,-2.359375 1.40625,-0.78125 3.23437,-0.78125 3.01563,0 4.5625,1.875 1.54688,1.859375 1.54688,5.484375 z"
                fill="#2F0C66"
                id="path24576"
            />
            <path
                d="m 282.52619,86.599716 v 1.40625 h -13.21875 q 0.1875,2.96875 1.78125,4.53125 1.60938,1.546875 4.46875,1.546875 1.65625,0 3.20313,-0.40625 1.5625,-0.40625 3.09375,-1.21875 v 2.71875 q -1.54688,0.65625 -3.17188,1 -1.625,0.34375 -3.29687,0.34375 -4.1875,0 -6.64063,-2.4375 -2.4375,-2.4375 -2.4375,-6.59375 0,-4.296875 2.3125,-6.8125 2.32813,-2.53125 6.26563,-2.53125 3.53125,0 5.57812,2.28125 2.0625,2.265625 2.0625,6.171875 z m -2.875,-0.84375 q -0.0312,-2.359375 -1.32812,-3.765625 -1.28125,-1.40625 -3.40625,-1.40625 -2.40625,0 -3.85938,1.359375 -1.4375,1.359375 -1.65625,3.828125 z"
                fill="#2F0C66"
                id="path24578"
            />
            <path
                d="m 299.86994,81.927841 q 1.07813,-1.9375 2.57813,-2.859375 1.5,-0.921875 3.53125,-0.921875 2.73437,0 4.21875,1.921875 1.48437,1.90625 1.48437,5.4375 v 10.5625 h -2.89062 v -10.46875 q 0,-2.515625 -0.89063,-3.734375 -0.89062,-1.21875 -2.71875,-1.21875 -2.23437,0 -3.53125,1.484375 -1.29687,1.484375 -1.29687,4.046875 v 9.890625 h -2.89063 v -10.46875 q 0,-2.53125 -0.89062,-3.734375 -0.89063,-1.21875 -2.75,-1.21875 -2.20313,0 -3.5,1.5 -1.29688,1.484375 -1.29688,4.03125 v 9.890625 h -2.89062 v -17.5 h 2.89062 v 2.71875 q 0.98438,-1.609375 2.35938,-2.375 1.375,-0.765625 3.26562,-0.765625 1.90625,0 3.23438,0.96875 1.34375,0.96875 1.98437,2.8125 z"
                fill="#2F0C66"
                id="path24580"
            />
            <path
                d="m 324.36994,87.271591 q -3.48437,0 -4.82812,0.796875 -1.34375,0.796875 -1.34375,2.71875 0,1.53125 1,2.4375 1.01562,0.890625 2.75,0.890625 2.39062,0 3.82812,-1.6875 1.45313,-1.703125 1.45313,-4.515625 v -0.640625 z m 5.73438,-1.1875 v 9.984375 h -2.875 v -2.65625 q -0.98438,1.59375 -2.45313,2.359375 -1.46875,0.75 -3.59375,0.75 -2.6875,0 -4.28125,-1.5 -1.57812,-1.515625 -1.57812,-4.046875 0,-2.953125 1.96875,-4.453125 1.98437,-1.5 5.90625,-1.5 h 4.03125 v -0.28125 q 0,-1.984375 -1.3125,-3.0625 -1.29688,-1.09375 -3.65625,-1.09375 -1.5,0 -2.92188,0.359375 -1.42187,0.359375 -2.73437,1.078125 v -2.65625 q 1.57812,-0.609375 3.0625,-0.90625 1.48437,-0.3125 2.89062,-0.3125 3.79688,0 5.67188,1.96875 1.875,1.96875 1.875,5.96875 z"
                fill="#2F0C66"
                id="path24582"
            />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear"
                x1="-40.37"
                y1="66.1299"
                x2="234.09"
                y2="27.4699"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.25" stopColor="#73A0F8" />
                <stop offset="0.6" stopColor="#FF63F8" />
                <stop offset="0.79" stopColor="#931EDF" />
                <stop offset="0.88" stopColor="#6400D4" />
            </linearGradient>
            <linearGradient
                id="paint1_linear"
                x1="-15.97"
                y1="-5.42003"
                x2="95.9"
                y2="164.06"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.15" stopColor="#6AC7FF" />
                <stop offset="0.22" stopColor="#6CB8FC" />
                <stop offset="0.35" stopColor="#7190F5" />
                <stop offset="0.52" stopColor="#7950EA" />
                <stop offset="0.72" stopColor="#8300DC" />
            </linearGradient>
            <clipPath id="clip0">
                <rect width="351" height="107" fill="white" />
            </clipPath>
        </defs>
    </svg>
)
