import Icon, { CheckCircleFilled } from '@ant-design/icons'
import SvgEllipsisCircle from '../../../../../icons/EllipsisCircleFilled'
import { ChecklistStateIconProps } from './types'
import CircleFilled from '../../../../../icons/CircleFilled'
import { Flex } from 'antd'
import { COMPLETED_COLOR, STARTED_COLOR, TODO_COLOR } from '../../../constants'

export const ChecklistStateIcon = (props: ChecklistStateIconProps) => {
    if (props.isMarkedAsCompleted) {
        return <CheckCircleFilled style={{ ...props.style, color: COMPLETED_COLOR }} onClick={props.onClick} />
    }

    if (props.isStartDetected) {
        return (
            <Icon
                component={SvgEllipsisCircle}
                style={{ ...props.style, color: STARTED_COLOR }}
                onClick={props.onClick}
            />
        )
    }

    return (
        <Flex justify="space-around" align="center" onClick={props.onClick}>
            <div style={{ position: 'absolute', color: 'white', fontSize: '1.5em' }}>{props.stepNumber}</div>
            <Icon component={CircleFilled} style={{ ...props.style, color: TODO_COLOR }} />
        </Flex>
    )
}
