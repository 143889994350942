/* eslint-disable prettier/prettier */
import { IScheduleTransform } from 'common-api'
import _, { max } from 'lodash'
import { useRef } from 'react'
import { useDrop } from 'react-dnd'
import { useDispatch } from 'react-redux'
import type { CourseRoundAccessor, TeacherAccessor } from '../../schedule-access/scheduleAccessWrappers'
import { locallyTriggeredScheduleTransform } from '../../store/schedule/actions'
import { useLocalSchedule } from '../../store/schedule/hooks'
import brandColors from '../../styles/colors/brandColors'
import { DndItemTypes } from '../../utils/DndItemTypes'
import { comparing } from '../../utils/compareUtil'
import styled from '../../utils/styled'
import { DraggableCourseRound } from './CourseRound'
import { DragLayer } from './DragLayer'
import { Positioned } from './Positioned'
import {
    HEADER_WIDTH,
    ROW_HEIGHT,
    TEACHER_FULL_TIME_HOURS,
    hoursToWidth,
    indexToHeight,
    mousePositionToRow
} from './util'

export const TeachingAssignments = () => {
    const dispatch = useDispatch()
    const schedule = useLocalSchedule()

    const dropWrapper = useRef<HTMLDivElement>(null)

    const teachers = schedule.getTeachers().sort(comparing((t) => t.getTeacherSchoolId()))

    const maxVisibleHours =
        1.15 *
        Math.max(
            TEACHER_FULL_TIME_HOURS,
            _.max(teachers.map(teacherCourseRoundsLayout).map((crs) => _.sumBy(crs, (crs) => crs.totalHours))) || 0
        )

    function teacherCourseRoundsLayout(
        teacher: TeacherAccessor
    ): { cr: CourseRoundAccessor; startHour: number; totalHours: number }[] {
        const courseRound = schedule
            .getCourseRounds()
            .filter((cr) => cr.getTeachers().some((t) => t.getTeacherId() === teacher.getTeacherId()))
            .sort((a, b) => a.getDisplayName().localeCompare(b.getDisplayName()))

        let start = 0
        return courseRound.map((cr) => {
            const crStart = start
            const crWidth = hoursToWidth(cr.getTotalHours())
            start += hoursToWidth(cr.getTotalHours())
            return { cr, startHour: crStart, totalHours: crWidth }
        })
    }

    const crtws = teachers.map((teacher) => teacherCourseRoundsLayout(teacher))
    const maxByRow = crtws.map((crsInRow) => max(crsInRow.map((crws) => crws.startHour + crws.totalHours)) || 0)

    const [, drop] = useDrop(
        () => ({
            accept: DndItemTypes.EVENT,
            drop: (item: any, monitor) => {
                const currentOffset = monitor.getClientOffset()
                const row = mousePositionToRow(currentOffset, dropWrapper.current?.getBoundingClientRect())
                if (row !== undefined && row >= 0 && row < teachers.length) {
                    const transform = IScheduleTransform.courseRoundTransform({
                        newCourseRound: {
                            ...schedule.findCourseRound(item.courseRoundId).getConjureObject(),
                            teacherIds: [teachers[row].getTeacherId()]
                        }
                    })
                    dispatch(locallyTriggeredScheduleTransform(transform))
                }
            }
        }),
        [teachers]
    )

    const sortedCourseRounds = crtws
        .flatMap((crsInRow, row) => crsInRow.map((crws) => ({ row, crws })))
        .sort(comparing((crr) => crr.crws.cr.getCourseRoundId()))

    return (
        <Wrapper ref={drop}>
            {teachers.map((teacher, index) => (
                <RowWrapper key={teacher.getTeacherId()} index={index}>
                    <TeacherName key={teacher.getTeacherId()} index={index}>
                        <TeacherNameBackground>
                            <div>{teacher.getTeacherSchoolId()}</div>
                            <div style={{ marginTop: '.3em' }}>
                                {sortedCourseRounds
                                    .filter((cr) => cr.row === index)
                                    .map((cr) => cr.crws.totalHours)
                                    .reduce((prev, current) => prev + current, 0)}
                                h &nbsp;(
                                {Math.round(
                                    (100 *
                                        sortedCourseRounds
                                            .filter((cr) => cr.row === index)
                                            .map((cr) => cr.crws.totalHours)
                                            .reduce((prev, current) => prev + current, 0)) /
                                        TEACHER_FULL_TIME_HOURS
                                )}
                                %)
                            </div>
                        </TeacherNameBackground>
                    </TeacherName>
                    <HoursWrapper>
                        <Blocked
                            maxVisibleHours={maxVisibleHours}
                            workHours={(TEACHER_FULL_TIME_HOURS * teacher.getWorkPercentage()) / 100}
                        />
                    </HoursWrapper>
                </RowWrapper>
            ))}
            <CourseRounds>
                {sortedCourseRounds.map(({ crws, row }) => (
                    <Positioned
                        key={crws.cr.getCourseRoundId()}
                        row={row}
                        startHour={crws.startHour}
                        totalHours={crws.totalHours}
                        maxVisibleHours={maxVisibleHours}
                    >
                        <DraggableCourseRound courseRoundId={crws.cr.getCourseRoundId()} row={row} />
                    </Positioned>
                ))}
                <DragLayerWrapper ref={dropWrapper}>
                    <DragLayer maxes={maxByRow} maxVisibleHours={maxVisibleHours} />
                </DragLayerWrapper>
            </CourseRounds>
        </Wrapper>
    )
}

interface RowHeaderProps {
    index: number
}

interface BlockedProps {
    maxVisibleHours: number
    workHours: number
}

const Wrapper = styled('div')`
    position: relative;
    overflow: scroll;
    width: 100%;
    height: 100%;
    margin: 1em;
`

const RowWrapper = styled('div')<RowHeaderProps>`
    position: absolute;
    top: ${(props) => indexToHeight(props.index)}px;
    height: ${ROW_HEIGHT}px;
    width: 100%;
    background: ${(props) => (props.index % 2 === 0 ? brandColors.gray2 : brandColors.white)};
`

const TeacherName = styled('div')<RowHeaderProps>`
    display: flex;
    position: absolute;
    top: 0px;
    height: ${ROW_HEIGHT}px;
    width: ${HEADER_WIDTH};

    align-items: center;
`

const TeacherNameBackground = styled('div')`
    display: table-cell;
    padding: 0.5em;
    font-size: 0.8em;
`

const CourseRounds = styled('div')`
    position: relative;
    height: 100%;
    left: ${HEADER_WIDTH}px;
    width: calc(100% - ${HEADER_WIDTH}px);
`

const HoursWrapper = styled('div')`
    position: relative;
    top: 0;
    left: ${HEADER_WIDTH}px;
    width: calc(100% - ${HEADER_WIDTH}px);
    height: 100%;
`

export const Blocked = styled('div')<BlockedProps>`
    position: absolute;
    opacity: 0.3;
    background: red;

    top: 0;
    left: ${(props) => (100 * props.workHours) / props.maxVisibleHours}%;
    width: ${(props) => 100 * (1 - props.workHours / props.maxVisibleHours)}%;
    height: 100%;
`

const DragLayerWrapper = styled('div')`
    position: absolute;
    pointer-events: none;
    z-index: 100;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
`
