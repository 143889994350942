import { DayOfWeek } from 'common-api'
import { useEffect } from 'react'
import type { DragSourceMonitor } from 'react-dnd'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { useDispatch } from 'react-redux'
import {
    setLectureSelection,
    setReservedTimeSelection,
    toggleLectureSelection,
    toggleReservedTimeSelection
} from '../../../store/lectureselector/actions'
import { useLocalSchedule } from '../../../store/schedule/hooks'
import brandColors from '../../../styles/colors/brandColors'
import { DndItemTypes } from '../../../utils/DndItemTypes'
import styled from '../../../utils/styled'
import { Positioned } from '../styled'
import { Lecture } from './Lecture/Lecture'
import { ReservedTime } from './ReservedTime'
import type { ScheduleEvent } from './ScheduleEvent'
import { LectureEvent } from './ScheduleEvent'
import type { LayoutSpec } from './util'
import { positioningZIndex } from './util'
import { RoomNameAndPin } from './RoomNameAndPin'
import { CommaSeparatedList } from '../../../components/CommaSeparatedList'

interface PositionedLectureProps {
    event: ScheduleEvent
    layoutSpec: LayoutSpec
    transition?: string
}

export const EventComponent = ({
    event,
    layoutSpec,
    transition = 'width 0.5s ease 0s, top 1s ease 0s, left 1s ease 0s'
}: PositionedLectureProps) => {
    const dispatch = useDispatch()
    const zIndex = positioningZIndex(layoutSpec)
    const schedule = useLocalSchedule()
    const startOfDay = schedule.getSettings().startOfDay!
    const endOfDay = schedule.getSettings().endOfDay!

    const eventsToDrag = (event: ScheduleEvent): ScheduleEvent[] => {
        return event.accept<ScheduleEvent[]>({
            visitLecture: (l) =>
                l
                    .getLecture()
                    .getEventGroup()
                    .getLectures()
                    .map((l) => new LectureEvent(l)),
            visitReservedTime: (rt) => [rt]
        })
    }

    const [{ isDragging }, drag, preview] = useDrag(
        () => ({
            type: DndItemTypes.EVENT,
            item: { event: eventsToDrag(event), layoutSpec },
            collect: (monitor: DragSourceMonitor) => ({
                isDragging: monitor.isDragging()
            })
        }),
        [event, layoutSpec]
    )

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true })
    }, [preview])

    const opacity = isDragging ? 0.3 : 1

    return (
        <Positioned
            startOfDay={startOfDay}
            endOfDay={endOfDay}
            ref={drag}
            transition={transition}
            opacity={opacity}
            layoutSpec={layoutSpec}
            duration={event.getDurationInMinutes()}
            zIndex={zIndex}
        >
            {event.accept({
                visitLecture: (lecture) => (
                    <Lecture
                        dayAndTime={layoutSpec.type === 'allocated' ? layoutSpec.dayAndTime : undefined}
                        lecture={lecture.getLecture()}
                        onClick={(e) => {
                            dispatch(
                                e.shiftKey
                                    ? toggleLectureSelection(lecture.getLecture().getLectureId())
                                    : setLectureSelection([lecture.getLecture().getLectureId()])
                            )
                        }}
                    >
                        <CourseRoundName>{lecture.getLecture().getCourseRound().getDisplayName()}</CourseRoundName>
                        <div>
                            <CommaSeparatedList>
                                {lecture
                                    .getLecture()
                                    .getRoomAssignments()
                                    .filter((ra) => ra.getRoom() !== undefined)
                                    .map((ra) => (
                                        <RoomNameAndPin roomName={ra.getRoom()!.getName()} pinned={ra.isPinned()} />
                                    ))}
                            </CommaSeparatedList>
                        </div>
                        <div>
                            <CommaSeparatedList>
                                {lecture
                                    .getLecture()
                                    .getEffectiveTeachers()
                                    .map((t) => t.getTeacherSchoolId())}
                            </CommaSeparatedList>
                        </div>
                    </Lecture>
                ),
                visitReservedTime: (reservedTimeEvent) => {
                    const startTime =
                        layoutSpec.type === 'allocated'
                            ? layoutSpec.dayAndTime
                            : { day: DayOfWeek.MONDAY, hour: 0, minute: 0 }
                    return (
                        <ReservedTime
                            dayAndTime={startTime}
                            reservedTime={reservedTimeEvent.getReservedTime()}
                            onClick={(e) => {
                                dispatch(
                                    e.shiftKey
                                        ? toggleReservedTimeSelection(
                                              reservedTimeEvent.getReservedTime().getReservedTimeId()
                                          )
                                        : setReservedTimeSelection([
                                              reservedTimeEvent.getReservedTime().getReservedTimeId()
                                          ])
                                )
                            }}
                        >
                            {reservedTimeEvent.getReservedTime().getTitle().length > 0 && (
                                <div style={{ lineHeight: '1em' }}>
                                    <span
                                        style={{
                                            color: 'white',
                                            background: brandColors.blocker,
                                            padding: '3px 2px 2px 2px'
                                        }}
                                    >
                                        {reservedTimeEvent.getReservedTime().getTitle()}
                                    </span>
                                </div>
                            )}
                        </ReservedTime>
                    )
                }
            })}
        </Positioned>
    )
}

export default EventComponent

const CourseRoundName = styled('div')`
    overflow: hidden;
`

const RoomName = styled('div')`
    overflow: hidden;
`
