import { Flex, Table } from 'antd'
import type { TeacherId } from '../../../../commonTypes'
import { VisibleColumnsSelector } from '../../../../components/VisibleColumnsSelector'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import { comparing } from '../../../../utils/compareUtil'
import { rowFilter } from '../../../../utils/filtering'
import { FilterInput } from '../../../schedule/components/FilterInput'
import { useFiltering } from '../../../schedule/components/FilterInput/hooks'
import { AddTeacherButton } from '../NewTeacherButton'
import { useTeacherColumns } from './columns'
import type { TeacherRow, TeachersTableProps } from './types'

export const TeachersTable = ({ selectedRowKeys, setSelectedRowKeys }: TeachersTableProps) => {
    const filtering = useFiltering()
    const schedule = useLocalSchedule()
    const { columns, setVisibleColumns } = useTeacherColumns(filtering)

    const dataSourceUnfiltered = schedule.getTeachers().map((teacher) => ({ teacher }))
    const dataSource = dataSourceUnfiltered
        .filter(rowFilter(filtering, columns))
        .toSorted(comparing(({ teacher }) => teacher.getTeacherSchoolId()))

    return (
        <Table<TeacherRow>
            title={() => (
                <Flex gap={8}>
                    <FilterInput {...filtering} />
                    <VisibleColumnsSelector columns={columns} setVisibleColumns={setVisibleColumns} />
                    <AddTeacherButton onTeacherCreated={(newTeacherId) => setSelectedRowKeys([newTeacherId])} />
                </Flex>
            )}
            rowSelection={{
                selectedRowKeys,
                onChange: (newSelectedRowKeys) => setSelectedRowKeys(newSelectedRowKeys as TeacherId[])
            }}
            dataSource={dataSource}
            showSorterTooltip={false}
            bordered
            columns={columns}
            size="small"
            rowKey={({ teacher }) => teacher.getTeacherId()}
            sticky
            onRow={({ teacher }) => ({
                onClick: () => setSelectedRowKeys([teacher.getTeacherId()])
            })}
            style={{ height: '100%' }}
            pagination={false}
        />
    )
}
