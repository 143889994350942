import {
    DurationProblemType,
    INoRoomSetProblem,
    IProblem,
    IRequiredRoomAttributeProblem,
    IRoomConflictProblem,
    ISameCourseMultipleTimesOnSameDayProblem,
    IScheduleOverrunProblem,
    IStudentGroupBreakProblem,
    IStudentGroupConflictProblem,
    IStudentGroupLunchProblem,
    ITeacherBreakProblem,
    ITeacherLunchProblem,
    IUnscheduledLectureProblem
} from 'common-api'
import type { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import type { LectureId } from '../../../../commonTypes'
import type { ScheduleAccessor } from '../../../../schedule-access/scheduleAccessWrappers'
import { addMinutes, formatDuration, formatHHMM, seDayName } from '../../../../utils/DayAndTimeUtil'
import { AppError } from '../../../../utils/errorutil'
import { toTranslate } from '../../../../utils/miscUtil'
import { isProblemRelatedToStudentGroup, relatedTeachers } from '../../../../utils/problems'
import { courseRoundDisplayName } from '../../../../utils/scheduleUtils'
import { isClassStudentGroup } from '../../../../utils/studentGroupUtil'

const courseRoundText = (schedule: ScheduleAccessor, lectureId: LectureId) =>
    courseRoundDisplayName(schedule.findLecture(lectureId).getCourseRound(), true)

export const problemMessageText = (schedule: ScheduleAccessor, problem: IProblem, t: TFunction) => {
    return IProblem.visit(problem, {
        endTimeProblem: (p) =>
            `${t('LectureEndsTooLate')} ${courseRoundDisplayName(
                schedule.findLecture(p.lectureId).getCourseRound(),
                true
            )}`,

        teacherConflictProblem: (p) => {
            const day = seDayName(p.dayAndTime.day)
            const fromHHMM = formatHHMM(p.dayAndTime)
            const toHHMM = formatHHMM(addMinutes(p.dayAndTime, p.durationInMinutes))
            const teacherName = schedule.findTeacher(p.teacherId).getTeacherSchoolId()
            return t('TeacherDoubleBooked', {
                teacherName,
                day,
                fromHHMM,
                toHHMM
            })
        },

        studentGroupLunchProblem: (p: IStudentGroupLunchProblem) => {
            return t('ClassHasTooShortLunch', {
                Class: schedule.findStudentGroup(p.studentGroupId).getDisplayName(),
                Day: seDayName(p.day)
            })
        },
        // `Klass ${schedule.findStudentGroup(p.studentGroupId).getDisplayName()} har ingen lunch på ${seDayName(p.day)}`,

        teacherBreakProblem: (p: ITeacherBreakProblem) => {
            // When a lecture is being dragged, it only has a (temporary) timestamp in the backend,
            // so one of the two lectures referred to in this problem can lack a day and time.
            const aLecture = schedule.findLecture(p.breakProblem.afterLectureId)
            const bLecture = schedule.findLecture(p.breakProblem.beforeLectureId)

            const d1 = aLecture.getDayAndTime()
            const d2 = bLecture.getDayAndTime()

            // TODO: With multiple teachers we actually need to include which teacher this problem refers to in the problem
            // object.
            const teacherId = aLecture.getCourseRound().getTeachers()[0].getTeacherSchoolId()

            if (d1 === undefined || d2 === undefined) {
                // This can happen if we have a local schedule update that remove the date/time.
                return t('TeacherHasTooShortBreak', { teacherId })
            }

            const day = seDayName((d1 || d2)!.day)
            return t('TeacherHasTooShortBreakOnDay', { teacherId, day })
        },

        studentGroupBreakProblem: (p: IStudentGroupBreakProblem) => {
            // When a lecture is being dragged, it only has a (temporary) timestamp in the backend,
            // so one of the two lectures referred to in this problem can lack a day and time.
            const aLecture = schedule.findLecture(p.breakProblem.afterLectureId)
            const bLecture = schedule.findLecture(p.breakProblem.beforeLectureId)

            const d1 = aLecture.getDayAndTime()
            const d2 = bLecture.getDayAndTime()

            if (d1 === undefined && d2 === undefined) {
                console.log('Error: Both d1 and d2 are undefined. This should not happen.')
                return toTranslate('Elever har för kort rast.')
            }

            const day = seDayName((d1 || d2)!.day)
            // TODO: Look for klasses in the overlap between sg for lecture 1 and sg for lecture 2.
            const sgName = aLecture.getCourseRound().getStudentGroup().getDisplayName()
            return t('StudentsHasTooShortBreakOnDay', { sgName, day })
        },

        roomConflictProblem: (p: IRoomConflictProblem) => {
            const day = seDayName(p.dayAndTime.day)
            const fromHHMM = formatHHMM(p.dayAndTime)
            const toHHMM = formatHHMM(addMinutes(p.dayAndTime, p.durationInMinutes))
            const roomName = schedule.findRoom(p.roomId).getName()
            return t('RoomDoubleBooked', { roomName, day, fromHHMM, toHHMM })
        },

        roomReservedTimeConflictProblem: (p) => {
            const roomName = schedule.findRoom(p.roomId).getName()
            const day = seDayName(p.dayAndTime.day)
            const fromHHMM = formatHHMM(p.dayAndTime)
            const toHHMM = formatHHMM(addMinutes(p.dayAndTime, p.durationInMinutes))
            return t('RoomScheduledOnBlockedTime', {
                roomName,
                day,
                fromHHMM,
                toHHMM
            })
        },

        requiredRoomAttributeProblem: (p: IRequiredRoomAttributeProblem) => {
            const roomName = schedule.findRoom(p.roomId).getName()
            const missingAttributes = p.missingAttributes
            return t('RoomMissingAttributes', { roomName, missingAttributes })
        },

        noRoomSetProblem: (p: INoRoomSetProblem) => `${t('NoRoomSelected')} ${courseRoundText(schedule, p.lectureId)}`,

        unscheduledLectureProblem: (p: IUnscheduledLectureProblem) =>
            `${t('NotScheduled')} ${courseRoundText(schedule, p.lectureId)}`,

        scheduleOverrunProblem: (p: IScheduleOverrunProblem) =>
            `${t('LectureEndsTooLate')} ${courseRoundText(schedule, p.lectureId)}`,

        sameCourseMultipleTimesOnSameDayProblem: (p: ISameCourseMultipleTimesOnSameDayProblem) =>
            `${t('SameCourseMultipleTimesOnSameDay')} ${courseRoundText(schedule, p.lectureIds[0])}`,

        sameSubjectMultipleTimesOnSameDayProblem: (p) => {
            const lecture = schedule.findLecture(p.lectureIds[0])
            const sgName = lecture.getCourseRound().getStudentGroup().getDisplayName()
            const subjectName = lecture.getCourseRound().getSubject().getName()
            return t('SameSubjectMultipleTimesOnSameDay', { subjectName, sgName })
        },

        studentGroupConflictProblem: (p: IStudentGroupConflictProblem) => {
            const day = seDayName(p.dayAndTime.day)
            const fromHHMM = formatHHMM(p.dayAndTime)
            const toHHMM = formatHHMM(addMinutes(p.dayAndTime, p.durationInMinutes))
            const sgNames = p.studentGroupIds
                .map((sgId) => schedule.findStudentGroup(sgId))
                .map((sg) => sg.getDisplayName())
                .join(', ')
            return t('StudentGroupsDoubleBooked', { sgNames, day, fromHHMM, toHHMM })
        },

        teacherFrameTimeProblem: (p) => {
            const teacherSchoolId = schedule.findTeacher(p.teacherId).getTeacherSchoolId()
            return t('TeacherFrameTimeExceeded', {
                teacherSchoolId,
                frameTime: formatDuration(p.frameTimeInMinutes)
            })
        },

        teacherLunchProblem: (p: ITeacherLunchProblem) => {
            const teacherSchoolId = schedule.findTeacher(p.teacherId).getTeacherSchoolId()
            const dayName = seDayName(p.day)
            return t('TeacherHasNoLunchBreak', { teacherSchoolId, dayName })
        },

        teacherReservedTimeConflictProblem: (p) => {
            const teacherSchoolId = schedule.findTeacher(p.teacherId).getTeacherSchoolId()
            const day = seDayName(p.dayAndTime.day)
            const fromHHMM = formatHHMM(p.dayAndTime)
            const toHHMM = formatHHMM(addMinutes(p.dayAndTime, p.durationInMinutes))
            return t('TeacherScheduledOnBlockedTime', {
                teacherSchoolId,
                day,
                fromHHMM,
                toHHMM
            })
        },

        studentGroupReservedTimeConflictProblem: (p) => {
            const studentGroupName = schedule.findStudentGroup(p.studentGroupId).getDisplayName()
            const day = seDayName(p.dayAndTime.day)
            const fromHHMM = formatHHMM(p.dayAndTime)
            const toHHMM = formatHHMM(addMinutes(p.dayAndTime, p.durationInMinutes))
            return toTranslate(
                `Elevgrupp schemalagd på blockerad tid: ${studentGroupName} på ${day} kl ${fromHHMM}-${toHHMM}`
            )
        },

        lectureDurationProblem: (p) => {
            const adjective = p.type === DurationProblemType.TOO_SHORT ? t('Short') : t('Long')
            return `${t('LectureIsToo')} ${adjective}: ${courseRoundText(schedule, p.lectureId)}`
        },

        totalCourseRoundDuration: (p) => {
            return `${schedule.findCourseRound(p.courseRoundId).getDisplayName()} ${toTranslate('har för')} ${
                p.type === DurationProblemType.TOO_LONG ? t('Many') : t('Few')
            } ${t('HoursSmall')}`
        },

        unknown: (problem: IProblem) => {
            throw new AppError('Failed to render schedule problem type', { problem })
        }
    })
}
export const problemTypeLabel = (problem: IProblem, t: TFunction) =>
    IProblem.visit<string>(problem, {
        studentGroupReservedTimeConflictProblem: () => toTranslate('Elevgrupp schemalagd på blockerad tid'),
        endTimeProblem: () => t('ProblemTypeLabel.EndTimeProblem'),
        teacherConflictProblem: () => t('ProblemTypeLabel.TeacherConflictProblem'),
        studentGroupLunchProblem: () => t('ProblemTypeLabel.StudentGroupLunchProblem'),
        teacherBreakProblem: () => t('ProblemTypeLabel.TeacherBreakProblem'),
        studentGroupBreakProblem: () => t('ProblemTypeLabel.StudentGroupBreakProblem'),
        roomConflictProblem: () => t('ProblemTypeLabel.RoomConflictProblem'),
        roomReservedTimeConflictProblem: () => t('ProblemTypeLabel.RoomReservedTimeConflictProblem'),
        requiredRoomAttributeProblem: () => t('ProblemTypeLabel.RequiredRoomAttributeProblem'),
        noRoomSetProblem: () => t('ProblemTypeLabel.NoRoomSetProblem'),
        unscheduledLectureProblem: () => t('ProblemTypeLabel.UnscheduledLectureProblem'),
        scheduleOverrunProblem: () => t('ProblemTypeLabel.ScheduleOverrunProblem'),
        sameCourseMultipleTimesOnSameDayProblem: () => t('ProblemTypeLabel.SameCourseMultipleTimesOnSameDayProblem'),
        sameSubjectMultipleTimesOnSameDayProblem: () => t('ProblemTypeLabel.SameSubjectMultipleTimesOnSameDayProblem'),
        studentGroupConflictProblem: () => t('ProblemTypeLabel.StudentGroupConflictProblem'),
        teacherFrameTimeProblem: () => t('ProblemTypeLabel.TeacherFrameTimeProblem'),
        teacherLunchProblem: () => t('ProblemTypeLabel.TeacherLunchProblem'),
        teacherReservedTimeConflictProblem: () => t('ProblemTypeLabel.TeacherReservedTimeConflictProblem'),
        lectureDurationProblem: (p) =>
            toTranslate('Lektion för ' + (p.type === DurationProblemType.TOO_LONG ? 'lång' : 'kort')),
        totalCourseRoundDuration: (p) =>
            toTranslate('Kursomgång har för ' + (p.type === DurationProblemType.TOO_LONG ? 'många' : 'få') + 'timmar'),
        unknown: (problem) => {
            throw new AppError('Failed to render schedule problem type', { problem })
        }
    })

export const useClassLabels = () => {
    const { t } = useTranslation()

    const getClassLabels = (problem: IProblem, schedule: ScheduleAccessor) => {
        const classLabels = schedule
            .getStudentGroups()
            .filter(isClassStudentGroup)
            .filter((csg) => isProblemRelatedToStudentGroup(schedule, problem, csg))
            .map((csg) => csg.getDisplayName())

        return classLabels.length > 0 ? classLabels : [t('NoClass')]
    }
    return { getClassLabels }
}

export const teacherLabels = (problem: IProblem, schedule: ScheduleAccessor) =>
    relatedTeachers(schedule, problem)
        .map((tid) => schedule.findTeacher(tid))
        .map((t) => t.getTeacherSchoolId())
