import { Space } from 'antd'
import { IScheduleTransform } from 'common-api'
import { useDispatch } from 'react-redux'
import type { EventGroupAccessor, ReservedTimeAccessor } from '../../schedule-access/scheduleAccessWrappers'
import { useActiveOrgId } from '../../store/auth/hooks'
import { clearLectureSelection } from '../../store/lectureselector/actions'
import { locallyTriggeredScheduleTransform } from '../../store/schedule/actions'
import { useIsDummySchedule, useLocalSchedule } from '../../store/schedule/hooks'
import Button from '../Button'
import { Endpoints } from '../../services/Endpoints'

export const DevUtilPanel = () => {
    const activeOrgId = useActiveOrgId()
    const isDummySchedule = useIsDummySchedule()
    const schedule = useLocalSchedule()
    const dispatch = useDispatch()

    const resetDayAndTime = (eventGroup: EventGroupAccessor) =>
        IScheduleTransform.eventGroupTransform({
            newEventGroup: {
                ...eventGroup.getConjureObject(),
                dayAndTime: null
            }
        })

    const deleteEventGroup = (eventGroup: EventGroupAccessor) =>
        IScheduleTransform.eventGroupDeleteTransform({
            eventGroupId: eventGroup.getEventGroupId()
        })

    const deleteReservedTime = (rt: ReservedTimeAccessor) =>
        IScheduleTransform.reservedTimeDeleteTransform({
            reservedTimeId: rt.getReservedTimeId()
        })

    const onResetDayAndTime = () => {
        const eventGroupResets = schedule.getEventGroups().map(resetDayAndTime)
        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(eventGroupResets)))
    }

    const unpinEventGroup = (eventGroup: EventGroupAccessor) =>
        IScheduleTransform.eventGroupTransform({
            newEventGroup: {
                ...eventGroup.getConjureObject(),
                timeslotPinned: false
            }
        })

    const onResetDayAndTimeNonPinned = () => {
        const lectureResets = schedule
            .getEventGroups()
            .filter((l) => !l.isTimeslotPinned())
            .map(resetDayAndTime)
        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(lectureResets)))
    }

    const unpinTimeForAllLectures = () => {
        const bulkUnpinTransform = schedule.getEventGroups().map(unpinEventGroup)
        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(bulkUnpinTransform)))
    }

    const deleteUnscheduledLectures = () => {
        const deleteTransforms = schedule
            .getEventGroups()
            .filter((eg) => !eg.isScheduled())
            .map(deleteEventGroup)
        dispatch(clearLectureSelection())
        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(deleteTransforms)))
    }

    const loadDemoData = () => {
        Endpoints.service.loadDemoState(activeOrgId)
    }

    const deleteAllReservedTimes = () => {
        const deleteTransforms = schedule.getReservedTimes().map(deleteReservedTime)
        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(deleteTransforms)))
    }

    const dumpScheduleJson = () => {
        console.log(schedule)
    }

    const infoTableRow = (key: string, value: string | number) => (
        <tr key={key}>
            <th style={{ textAlign: 'left' }}>{key}</th>
            <td style={{ fontFamily: 'monospace' }}>{value}</td>
        </tr>
    )

    return (
        <Space direction="vertical">
            <Button variant="primary" disabled={isDummySchedule} destructive size="sm" onClick={onResetDayAndTime}>
                Ta bort dag/tid från alla lektioner
            </Button>
            <Button
                variant="primary"
                disabled={isDummySchedule}
                destructive
                size="sm"
                onClick={onResetDayAndTimeNonPinned}
            >
                Ta bort dag/tid från alla icke-pinnade lektioner
            </Button>
            <Button
                variant="primary"
                disabled={isDummySchedule}
                destructive
                size="sm"
                onClick={unpinTimeForAllLectures}
            >
                Ta bort alla pins för dag / tid
            </Button>
            <Button
                variant="primary"
                disabled={isDummySchedule}
                destructive
                size="sm"
                onClick={deleteUnscheduledLectures}
            >
                Ta bort icke schemalagda lektioner
            </Button>
            <Button variant="primary" disabled={isDummySchedule} destructive size="sm" onClick={dumpScheduleJson}>
                Skriv ut aktuellt schema i konsolen.
            </Button>
            <Button variant="primary" disabled={isDummySchedule} destructive size="sm" onClick={deleteAllReservedTimes}>
                Ta bort alla reserverade tider
            </Button>
            <Button variant="primary" disabled={isDummySchedule} destructive size="sm" onClick={loadDemoData}>
                Ladda demodata
            </Button>
            <table>
                <tbody>
                    {infoTableRow('Active org id', activeOrgId)}
                    {infoTableRow('Current schedule version', isDummySchedule ? 'n/a' : schedule.getVersion())}
                    {[
                        'REACT_APP_API_URL',
                        'REACT_APP_VERSION',
                        'REACT_APP_MEITNER_SSO_URL',
                        'REACT_APP_MEITNER_API_VERSION',
                        'REACT_APP_MEITNER_ADMIN_URL'
                    ].map((varName) => infoTableRow(varName, process.env[varName] || 'n/a'))}
                </tbody>
            </table>
        </Space>
    )
}
