import type { IScheduleTransform } from 'common-api'
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import { v4 as uuid } from 'uuid'
import { Endpoints } from '../../services/Endpoints'
import { throwOnUndefined } from '../../utils/miscUtil'
import type { ApplicationState } from '../index'
import {
    appendPendingScheduleTransform,
    assignVersionToPendingScheduleTransform,
    fetchedProblematicTimeslotsForLecture,
    serverTriggeredScheduleTransform
} from './actions'
import { ScheduleActionTypes } from './types'
import { setScheduleSseConnectionState } from '../devmode/actions'
import { sseConnectDisconnectLoop } from '../../utils/sse/sse'

const apiBaseUrl = process.env.REACT_APP_API_URL ?? 'http://localhost:3000/api'
const sseUrl = `${apiBaseUrl}/schedule`

function* handleLocallyTriggeredScheduleTransform(action: any): any {
    const transform = action.payload satisfies IScheduleTransform

    // Record this schedule transform locally immediately so that the user doesn't have to wait for a server round trip
    // for the change to take effect on screen.
    //
    // This transform will get a version associated with it further down in the saga.
    const transformId = uuid()
    yield put(appendPendingScheduleTransform(transform, transformId))

    // Submit transform to server
    const scheduleId = yield select((state: ApplicationState) =>
        throwOnUndefined(state.schedule.schedule, 'Expected schedule to be defined').getScheduleId()
    )
    const version = yield call([Endpoints.service, Endpoints.service.updateSchedule], scheduleId, transform)

    // Assign version to the transform so that we know when it's safe to garbage collect the transform in the future.
    yield put(assignVersionToPendingScheduleTransform(transformId, version))
}

function* watchUpdatesSchedule() {
    yield takeEvery(ScheduleActionTypes.LOCALLY_TRIGGERED_SCHEDULE_TRANSFORM, handleLocallyTriggeredScheduleTransform)
}

function* handleStartDragEventGroup(action: any): any {
    const { scheduleId, eventGroupId } = action.payload
    const result = yield call(
        [Endpoints.service, Endpoints.service.getProblematicTimeslotsForEventGroup],
        scheduleId,
        eventGroupId
    )

    yield put(fetchedProblematicTimeslotsForLecture(result))
}

function* watchStartDragLecture() {
    yield takeEvery(ScheduleActionTypes.START_DRAG_EVENT_GROUP, handleStartDragEventGroup)
}

function* processScheduleUpdate(action: any) {
    const schedule = JSON.parse(action.data)
    yield put(serverTriggeredScheduleTransform(schedule))
}

function* scheduleSubscriptionsSaga(): any {
    yield call(
        sseConnectDisconnectLoop,
        [ScheduleActionTypes.SUBSCRIBE, ScheduleActionTypes.UNSUBSCRIBE],
        (scheduleId: string) => `${sseUrl}/${scheduleId}/updates`,
        'schedule-update',
        (s: ApplicationState) => s.schedule.subscriptionCount,
        processScheduleUpdate,
        setScheduleSseConnectionState
    )
}

function* scheduleSaga() {
    yield all([fork(watchUpdatesSchedule), fork(watchStartDragLecture), fork(scheduleSubscriptionsSaga)])
}

export default scheduleSaga
