import type { ITimeOfDay } from 'common-api'
import type { XYCoord } from 'react-dnd'
import type { AllocatedLayoutSpec } from '../util'
import { positionToLayoutSpec } from '../util'

type LayoutSpecOptions = {
    currentOffset: XYCoord | null
    initialOffset: XYCoord | null
    dropBounds: DOMRect
    item: any
}

type LayoutSpecReturnType = AllocatedLayoutSpec | undefined

const getLayoutSpec = (
    startOfDay: ITimeOfDay,
    endOfDay: ITimeOfDay,
    { currentOffset, initialOffset, dropBounds, item }: LayoutSpecOptions
): LayoutSpecReturnType => {
    if (!currentOffset || !initialOffset || !dropBounds || !item) {
        return undefined
    }

    const layoutSpec = positionToLayoutSpec(
        startOfDay,
        endOfDay,
        currentOffset,
        dropBounds,
        initialOffset,
        item.layoutSpec
    )

    if (!layoutSpec) {
        return undefined
    }

    return layoutSpec
}

export { getLayoutSpec }
