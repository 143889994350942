import { Select } from 'antd'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import { RoomSelectorProps } from './types'
import { toTranslate } from '../../../../utils/miscUtil'

export const RoomSelector = ({ value, onChange }: RoomSelectorProps) => {
    const schedule = useLocalSchedule()
    const roomOptions = schedule.getRooms().map((r) => ({ label: r.getName(), value: r.getRoomId() }))

    return (
        <Select
            options={roomOptions}
            value={value}
            allowClear
            showSearch
            optionFilterProp="label"
            onChange={onChange}
            placeholder={toTranslate('Ingen sal vald')}
        />
    )
}
