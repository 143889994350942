import { useState } from 'react'
import type { TeacherId } from '../../commonTypes'
import TablePage from '../../components/PageContent'
import TeacherDetails from '../../components/teachers/TeacherDetails'
import { useLocalSchedule } from '../../store/schedule/hooks'
import { toTranslate } from '../../utils/miscUtil'
import { TeacherActionsPanel } from './TeacherActionsPanel'
import { TeachersTable } from './components/TeachersTable'

export const TeachersPage = () => {
    const schedule = useLocalSchedule()
    const [selectedRowKeys, setSelectedRowKeys] = useState<TeacherId[]>([])

    // Some selected teachers may no longer exists (for example if they were deleted in another browser tab).
    const validSelectedRowKeys = selectedRowKeys.filter((teacherId) => schedule.doesTeacherIdExist(teacherId))

    const detailsPanel = (
        <TeacherDetails
            key={schedule.getVersion() + validSelectedRowKeys.join(',')}
            teacherIds={validSelectedRowKeys}
        />
    )

    const actionsPanel = (
        <TeacherActionsPanel
            key={validSelectedRowKeys.join(',')}
            onDelete={() => setSelectedRowKeys([])}
            teacherIds={validSelectedRowKeys}
        />
    )

    return (
        <TablePage
            selectedRowKeys={validSelectedRowKeys}
            onEscape={() => setSelectedRowKeys([])}
            table={<TeachersTable selectedRowKeys={validSelectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} />}
            sidePanels={[
                { key: 'edit', header: toTranslate('Redigera'), panel: detailsPanel },
                {
                    key: 'actions',
                    header: toTranslate('Åtgärder'),
                    panel: actionsPanel
                }
            ]}
        />
    )
}
