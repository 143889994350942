import type * as api from 'common-api'
import type { IDayAndTime, ITimeOfDay } from 'common-api'
import { DayOfWeek } from 'common-api'
import { groupBy } from 'lodash'
import type { XYCoord } from 'react-dnd'
import type { LectureAccessor } from '../../../schedule-access/scheduleAccessWrappers'
import { findOrThrow } from '../../../utils/collections'
import { combinedCmpFn, comparing } from '../../../utils/compareUtil'
import type { LectureEvent, ScheduleEvent } from './ScheduleEvent'
import type { EventAndLayoutSpec } from './types'

export interface AllocatedLayoutSpec {
    type: 'allocated'
    dayAndTime: IDayAndTime
    column: number
    columns: number
}

export interface UnallocatedLayoutSpec {
    type: 'unallocated'
    row: number
    column: number
    columns: number
}

export type LayoutSpec = AllocatedLayoutSpec | UnallocatedLayoutSpec

export const DAYS = [
    DayOfWeek.MONDAY,
    DayOfWeek.TUESDAY,
    DayOfWeek.WEDNESDAY,
    DayOfWeek.THURSDAY,
    DayOfWeek.FRIDAY,
    DayOfWeek.SATURDAY,
    DayOfWeek.SUNDAY
]

export const VISIBLE_DAYS = [
    DayOfWeek.MONDAY,
    DayOfWeek.TUESDAY,
    DayOfWeek.WEDNESDAY,
    DayOfWeek.THURSDAY,
    DayOfWeek.FRIDAY
]

export const DAY_WIDTH_PERCENT = 100 / VISIBLE_DAYS.length

export const MINUTES_PER_SLOT = 5

export const ALLOCATED_PERCENT = 80 // rest is for the unallocated

export const MIN_UNALLOCATED_COLUMNS = 5

function hashCode(s: string) {
    let h = 0

    for (let i = 0; i < s.length; i++) {
        h = (Math.imul(31, h) + s.charCodeAt(i)) | 0
    }
    return h
}

export function colorByType(subjectName: string): string {
    const lower = subjectName.toLowerCase()
    const hash = hashCode(lower) % 360
    return `hsl(${hash}, 33%, 76%)`
}

export function toMinuteOfDay(time: { hour: number; minute: number }) {
    return 60.0 * time.hour + time.minute
}

export function minuteToDayAndTime(day: api.DayOfWeek, minutesOfDay: number): api.IDayAndTime {
    const hour = Math.floor(minutesOfDay / 60)
    const minute = (minutesOfDay - hour * 60) % 60
    return { day, hour, minute }
}

export function positioningDayLeft(day: api.DayOfWeek) {
    return (100.0 * DAYS.indexOf(day)) / VISIBLE_DAYS.length
}

export function positioningLeft(layoutSpec: LayoutSpec) {
    if (layoutSpec.type === 'allocated') {
        const dayIndex = DAYS.indexOf(layoutSpec.dayAndTime.day)
        return (100.0 * dayIndex) / VISIBLE_DAYS.length + (DAY_WIDTH_PERCENT * layoutSpec.column) / layoutSpec.columns
    }
    if (layoutSpec.type === 'unallocated') {
        return (100.0 * layoutSpec.column) / Math.max(layoutSpec.columns, MIN_UNALLOCATED_COLUMNS)
    }
    return -1
}

export function positioningWidth(layoutSpec: LayoutSpec) {
    if (layoutSpec.type === 'allocated') {
        return DAY_WIDTH_PERCENT / layoutSpec.columns
    }
    if (layoutSpec.type === 'unallocated') {
        return 100.0 / Math.max(layoutSpec.columns, MIN_UNALLOCATED_COLUMNS)
    }
    return -1
}

export function positioningTopRaw(startOfDay: ITimeOfDay, endOfDay: ITimeOfDay, hour: number, minutes: number) {
    const startMinuteOfDay = startOfDay.hour * 60 + startOfDay.minute
    const givenMinuteOfDay = hour * 60 + minutes
    const endMinuteOfDay = endOfDay.hour * 60 + endOfDay.minute
    const minutesInDay = endMinuteOfDay - startMinuteOfDay
    return (ALLOCATED_PERCENT * (givenMinuteOfDay - startMinuteOfDay)) / minutesInDay
}

export function positioningZIndex(layoutSpec: LayoutSpec): string | number {
    if (layoutSpec.type === 'allocated') {
        return 'auto'
    }
    if (layoutSpec.type === 'unallocated') {
        return layoutSpec.row
    }
    return -1
}

export function positioningTop(startOfDay: ITimeOfDay, endOfDay: ITimeOfDay, layoutSpec: LayoutSpec) {
    if (layoutSpec.type === 'allocated') {
        return positioningTopRaw(startOfDay, endOfDay, layoutSpec.dayAndTime.hour, layoutSpec.dayAndTime.minute)
    }
    if (layoutSpec.type === 'unallocated') {
        return ALLOCATED_PERCENT + 2 + 2 * layoutSpec.row
    }
    return -1
}

export function positioningHeightRaw(startOfDay: ITimeOfDay, endOfDay: ITimeOfDay, minutes: number) {
    const startMinuteOfDay = startOfDay.hour * 60 + startOfDay.minute
    const endMinuteOfDay = endOfDay.hour * 60 + endOfDay.minute
    const minutesInDay = endMinuteOfDay - startMinuteOfDay

    return (ALLOCATED_PERCENT * minutes) / minutesInDay
}

export function positionToLayoutSpec(
    startOfDay: ITimeOfDay,
    endOfDay: ITimeOfDay,
    currentOffset: XYCoord,
    dropBounds: DOMRect,
    initialOffset: XYCoord | undefined = undefined,
    initialLayoutSpec: LayoutSpec | undefined = undefined
): AllocatedLayoutSpec | null {
    const offsetTop =
        initialLayoutSpec && initialOffset
            ? (initialOffset.y - dropBounds.top) / dropBounds.height -
              positioningTop(startOfDay, endOfDay, initialLayoutSpec) / 100
            : 0

    const left = currentOffset.x - dropBounds.left
    const top = currentOffset.y - dropBounds.top

    const leftProcent = left / dropBounds.width
    const topProcent = top / dropBounds.height - offsetTop

    if (leftProcent >= 0 && leftProcent <= 1 && topProcent >= 0 && topProcent < ALLOCATED_PERCENT / 100) {
        const percentOfAllocated = topProcent / (ALLOCATED_PERCENT / 100)

        const startMinuteOfDay = startOfDay.hour * 60 + startOfDay.minute
        const endMinuteOfDay = endOfDay.hour * 60 + endOfDay.minute
        const minutesOfAllocated = endMinuteOfDay - startMinuteOfDay
        const decimalMinutes = toMinuteOfDay(startOfDay) + percentOfAllocated * minutesOfAllocated

        const minutesOfDay = 5 * Math.round(decimalMinutes / 5)
        const day = VISIBLE_DAYS[Math.floor(leftProcent * VISIBLE_DAYS.length)]

        return {
            type: 'allocated',
            column: 0,
            columns: 1,
            dayAndTime: minuteToDayAndTime(day, minutesOfDay)
        }
    }
    return null
}

// layout allocated events

interface BeginOrEndEvent {
    readonly type: 'BEGIN' | 'END'
    readonly event: ScheduleEvent
    readonly time: number
}

const getBeginAndEndEventForLecture = (event: ScheduleEvent): BeginOrEndEvent[] => {
    const start = toMinuteOfDay(event.getDayAndTime()!)
    return [
        { type: 'BEGIN', event, time: start },
        { type: 'END', event, time: start + event.getDurationInMinutes() }
    ]
}

function getBeginAndEndEventsForLectures(events: ScheduleEvent[]) {
    return events.flatMap(getBeginAndEndEventForLecture).sort(
        combinedCmpFn(
            comparing((event) => event.time),
            comparing((event) => (event.type === 'BEGIN' ? 1 : 0))
        )
    )
}

export function layoutAllocatedEvents(events: ScheduleEvent[]): EventAndLayoutSpec[] {
    const beginOrEndEvents = getBeginAndEndEventsForLectures(events)

    const columnAssignments = new Map<string, number>()
    const occupiedColumns: boolean[] = []

    let ret: EventAndLayoutSpec[] = []

    beginOrEndEvents.forEach(({ type, event }) => {
        if (type === 'BEGIN') {
            let firstUnoccupiedColumn = occupiedColumns.indexOf(false)
            if (firstUnoccupiedColumn === -1) {
                firstUnoccupiedColumn = occupiedColumns.length
                occupiedColumns.push(true)
            } else {
                occupiedColumns[firstUnoccupiedColumn] = true
            }
            columnAssignments.set(event.getUniqueId(), firstUnoccupiedColumn)
        } else {
            occupiedColumns[columnAssignments.get(event.getUniqueId())!] = false
        }

        // No occupied columns?
        const noColumnsOccupied = occupiedColumns.indexOf(true) === -1

        if (noColumnsOccupied) {
            // Lecture cluster complete. "Commit" values.
            // Cluster width is the "high water mark" of occupied columns
            const clusterWidth = occupiedColumns.length

            // Merge in column assignments (plus cluster width) into result map.
            columnAssignments.forEach((startColumn, eventId) => {
                const event = findOrThrow(events, eventId, (event) => event.getUniqueId())
                const lectureAndLayout: EventAndLayoutSpec = {
                    event,
                    layoutSpec: {
                        type: 'allocated',
                        dayAndTime: event.getDayAndTime()!,
                        column: startColumn,
                        columns: clusterWidth
                    }
                }

                ret = [lectureAndLayout, ...ret]
            })

            // Clear for next cluster
            columnAssignments.clear()

            // Clear high-water mark
            occupiedColumns.splice(0, occupiedColumns.length)
        }
    })

    return ret
}

export function layoutUnallocatedEvents(unallocatedLectures: LectureEvent[]): EventAndLayoutSpec[] {
    const courseRoundGroups = groupBy(unallocatedLectures, (l) => l.getLecture().getCourseRound().getCourseRoundId())
    const courseRoundIds = Object.keys(courseRoundGroups)
    return courseRoundIds
        .sort((a, b) => a.localeCompare(b))
        .flatMap((courseRoundId, index) =>
            courseRoundGroups[courseRoundId]
                .sort(comparing<LectureEvent>((l) => l.getDurationInMinutes()))
                .map((event, lectureIndex) => ({
                    event,
                    layoutSpec: {
                        type: 'unallocated',
                        column: index,
                        columns: courseRoundIds.length,
                        row: lectureIndex
                    }
                }))
        )
}
