import { useEffect } from 'react'
import type { DragSourceMonitor } from 'react-dnd'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import type { CourseRoundId } from '../../commonTypes'
import { DndItemTypes } from '../../utils/DndItemTypes'
import { CourseRoundCard } from './CourseRoundCard'

interface DraggableCourseRoundProps {
    courseRoundId: CourseRoundId
    row: number
}

export const DraggableCourseRound = (props: DraggableCourseRoundProps) => {
    const [_, drag, preview] = useDrag(
        () => ({
            type: DndItemTypes.EVENT,
            item: props,
            collect: (monitor: DragSourceMonitor) => ({
                isDragging: monitor.isDragging()
            })
        }),
        [props.courseRoundId, props.row]
    )

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true })
    }, [preview])

    return (
        <div ref={drag} style={{ height: '100%' }}>
            <CourseRoundCard courseRoundId={props.courseRoundId} />
        </div>
    )
}
