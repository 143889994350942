import type { ITimeProblemPair } from 'common-api'
import { useEffect, useRef } from 'react'
import { useDragLayer } from 'react-dnd'
import { useDispatch, useSelector } from 'react-redux'
import type { ApplicationState } from '../../../../store'
import { startDragLecture } from '../../../../store/schedule/actions'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import { DndItemTypes } from '../../../../utils/DndItemTypes'
import { addMinutes } from '../../../canteen-load/utils'
import EventComponent from '../EventComponent'
import { ProblemListPopover } from '../ProblemListPopover'
import type { ScheduleEvent } from '../ScheduleEvent'
import { isLectureEvent } from '../ScheduleEvent'
import { MINUTES_PER_SLOT, toMinuteOfDay } from '../util'
import RestrictedZones from './RestrictedZones'
import { DragLayerWrapper } from './styled'
import { getLayoutSpec } from './utils'
import { isVirtualProblem } from '../ProblemsList/utils'
import { useDevModeState } from '../../../../store/devmode/hooks'

export const DragLayer = () => {
    const devMode = useDevModeState()
    const dispatch = useDispatch()

    const schedule = useLocalSchedule()

    const problematicTimeSlots = useSelector<ApplicationState, ITimeProblemPair[]>((state) =>
        state.schedule.problematicTimeslotsForDraggedLecture.filter(
            (tpp) => devMode || !isVirtualProblem(tpp.problem.problem)
        )
    )

    const { itemType, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialClientOffset(),
        currentOffset: monitor.getClientOffset()
    }))

    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (item?.event[0]) {
            if (isLectureEvent(item.event[0])) {
                const lecture = item.event[0].getLecture()
                dispatch(
                    startDragLecture(lecture.getSchedule().getScheduleId(), lecture.getEventGroup().getEventGroupId())
                )
            }
        }
        return () => {}
    }, [dispatch, item])

    const startOfDay = schedule.getSettings().startOfDay!
    const endOfDay = schedule.getSettings().endOfDay!

    function renderItem() {
        if (itemType !== DndItemTypes.EVENT) {
            return null
        }

        const dropBounds = ref.current?.getBoundingClientRect()

        if (!dropBounds) {
            return null
        }

        const layoutSpec = getLayoutSpec(startOfDay, endOfDay, {
            currentOffset,
            initialOffset,
            dropBounds,
            item
        })

        if (layoutSpec) {
            const problems = problematicTimeSlots
                .filter((timeslot) => {
                    return timeslot.dayAndTime.day === layoutSpec.dayAndTime.day
                })
                .filter((timeslot) => {
                    const dragStart = toMinuteOfDay(layoutSpec.dayAndTime)
                    const start = toMinuteOfDay(timeslot.dayAndTime)
                    const end = start + MINUTES_PER_SLOT
                    return dragStart >= start && dragStart < end
                })
                .map((timeslot) => timeslot.problem)

            return (
                <>
                    {item.event.flatMap((itemEvent: ScheduleEvent, index: number) => {
                        return itemEvent.accept({
                            visitLecture: (lecture) => {
                                const eventComponent = (
                                    <EventComponent
                                        key={itemEvent.getUniqueId()}
                                        transition="all 0.1s ease 0s, top 0s"
                                        event={itemEvent}
                                        layoutSpec={{
                                            ...layoutSpec,
                                            dayAndTime: {
                                                day: layoutSpec.dayAndTime.day,
                                                ...addMinutes(
                                                    layoutSpec.dayAndTime,
                                                    lecture.getLecture().getRelativeStartTimeInMinutes()
                                                )
                                            },
                                            column: index,
                                            columns: item.event.length
                                        }}
                                    />
                                )

                                // Wrap component in ProblemListPopover if it's the last component
                                const lastEventComponent = index === item.event.length - 1
                                const possiblyWrappedEventComponent = lastEventComponent ? (
                                    <ProblemListPopover
                                        key={itemEvent.getUniqueId()}
                                        problems={problems}
                                        open={problems.length > 0}
                                    >
                                        {eventComponent}
                                    </ProblemListPopover>
                                ) : (
                                    eventComponent
                                )

                                return [possiblyWrappedEventComponent]
                            },
                            visitReservedTime: (rt) => {
                                return [
                                    <EventComponent
                                        key={itemEvent.getUniqueId()}
                                        transition="all 0.1s ease 0s, top 0s"
                                        event={itemEvent}
                                        layoutSpec={{
                                            ...layoutSpec,
                                            dayAndTime: layoutSpec.dayAndTime,
                                            column: index,
                                            columns: item.event.length
                                        }}
                                    />
                                ]
                            }
                        })
                    })}
                </>
            )
        }

        return null
    }

    return (
        <DragLayerWrapper ref={ref}>
            <RestrictedZones
                currentOffset={currentOffset}
                initialOffset={initialOffset}
                dropBounds={ref.current?.getBoundingClientRect() ?? new DOMRect()}
                item={item}
            />

            {renderItem()}
        </DragLayerWrapper>
    )
}
