import type { IWeekSelection } from 'common-api'
import { combineReducers } from 'redux'
import { all, fork } from 'redux-saga/effects'
import { authReducer } from './auth/reducer'
import authSaga from './auth/sagas'
import type { AuthState } from './auth/types'
import { autoSchedulerReducer } from './autoscheduler/reducer'
import autoSchedulerSaga from './autoscheduler/sagas'
import type { AutoSchedulerState } from './autoscheduler/types'
import { blockedTimeSelectionReducer } from './blockedtime/reducer'
import type { BlockedTimeSelectionState } from './blockedtime/types'
import { lectureSelectionReducer } from './lectureselector/reducer'
import type { LectureSelectionState } from './lectureselector/types'
import { scheduleReducer } from './schedule/reducer'
import scheduleSaga from './schedule/sagas'
import type { ScheduleState } from './schedule/types'
import { scheduleSelectionReducer } from './scheduleselector/reducer'
import type { ScheduleSelectionState } from './scheduleselector/types'
import { weekSelectionReducer } from './weekselector/reducer'
import scheduleSelectionSaga from './scheduleselector/sagas'
import weekSelectionSaga from './weekselector/sagas'
import { debugReducer } from './devmode/reducer'
import { DevInfoState } from './devmode/types'
import devModeSaga from './devmode/sagas'

export interface ApplicationState {
    authState: AuthState
    schedule: ScheduleState
    autoSchedulerState: AutoSchedulerState
    weekSelection: IWeekSelection
    lectureSelection: LectureSelectionState
    scheduleSelection: ScheduleSelectionState
    blockedTimeSelection: BlockedTimeSelectionState
    devInfoState: DevInfoState
}

export const createRootReducer = () =>
    combineReducers({
        authState: authReducer,
        schedule: scheduleReducer,
        autoSchedulerState: autoSchedulerReducer,
        weekSelection: weekSelectionReducer,
        scheduleSelection: scheduleSelectionReducer,
        lectureSelection: lectureSelectionReducer,
        blockedTimeSelection: blockedTimeSelectionReducer,
        devInfoState: debugReducer
    })

export function* rootSaga() {
    yield all([
        fork(scheduleSaga),
        fork(autoSchedulerSaga),
        fork(authSaga),
        fork(scheduleSelectionSaga),
        fork(weekSelectionSaga),
        fork(devModeSaga)
    ])
}
