import styled from '@emotion/styled'
import { ROW_HEIGHT, indexToHeight } from './util'

interface PositioningProps {
    maxVisibleHours: number
    row: number
    startHour: number
    totalHours: number
}

export const Positioned = styled('div')<PositioningProps>`
    transition: all 0.3s;
    position: absolute;
    border-top: 3px solid #ff000000;
    border-bottom: 3px solid #ff000000;
    border-left: 1px solid #ff000000;
    border-right: 1px solid #ff000000;
    cursor: grabbing;

    top: ${(props) => indexToHeight(props.row)}px;
    left: ${(props) => (100 * props.startHour) / props.maxVisibleHours}%;
    width: ${(props) => (100 * props.totalHours) / props.maxVisibleHours}%;
    height: ${ROW_HEIGHT}px;
`
