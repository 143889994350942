import { Form, Input, InputNumber, message } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { IScheduleTransform } from 'common-api'
import { cloneDeep } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import type { TeacherId } from '../../commonTypes'
import type { TeacherAccessor } from '../../schedule-access/scheduleAccessWrappers'
import { locallyTriggeredScheduleTransform } from '../../store/schedule/actions'
import { useLocalSchedule } from '../../store/schedule/hooks'
import { comparing } from '../../utils/compareUtil'
import { toTranslate } from '../../utils/miscUtil'
import Button from '../Button'

type TeacherDetailsProps = {
    teacherIds: TeacherId[]
}

type TeacherFormValues = {
    teacherSchoolIds: string[]
    firstNames: string[]
    lastNames: string[]
    workPercentages: number[]
}

const formValuesFromAccessors = (teachers: TeacherAccessor[]): TeacherFormValues => {
    const values: TeacherFormValues = {
        teacherSchoolIds: [],
        firstNames: [],
        lastNames: [],
        workPercentages: []
    }

    for (const accessor of teachers) {
        const conjureObj = accessor.getConjureObject()
        values.teacherSchoolIds.push(conjureObj.teacherSchoolId)
        values.firstNames.push(conjureObj.firstName || '')
        values.lastNames.push(conjureObj.lastName || '')
        values.workPercentages.push(conjureObj.workPercentage)
    }

    return values
}

const TeacherDetails = ({ teacherIds }: TeacherDetailsProps) => {
    const dispatch = useDispatch()
    const schedule = useLocalSchedule()
    const { t } = useTranslation()
    const [form] = useForm()

    const teachers = teacherIds.map((tid) => schedule.findTeacher(tid))
    teachers.sort(comparing((t) => t.getTeacherSchoolId()))

    const saveTeachers = (formValues: TeacherFormValues) => {
        const transforms = teachers.map((teacher, index) =>
            IScheduleTransform.teacherTransform({
                newTeacher: {
                    ...cloneDeep(teacher.getConjureObject()),
                    teacherSchoolId: formValues.teacherSchoolIds[index],
                    firstName: formValues.firstNames[index],
                    lastName: formValues.lastNames[index],
                    workPercentage: formValues.workPercentages[index]
                }
            })
        )
        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(transforms)))
        message.success(toTranslate(teachers.length === 1 ? 'Lärare sparad' : 'Lärare sparade'))
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Form
                form={form}
                name="teacherForm"
                initialValues={formValuesFromAccessors(teachers)}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: '700px', width: '100%' }}
                onFinish={saveTeachers}
                labelWrap
                colon={false}
            >
                <Form.List name="teacherSchoolIds">
                    {(fields) =>
                        fields.map((field, index) => (
                            // biome-ignore lint/correctness/useJsxKeyInIterable: can be removed when we switch form components
                            <Form.Item
                                label={index === 0 ? t('Signature') : ' '}
                                style={{
                                    marginBottom: index === teachers.length - 1 ? undefined : '5px'
                                }}
                                {...field}
                            >
                                <Input />
                            </Form.Item>
                        ))
                    }
                </Form.List>
                <Form.List name="firstNames">
                    {(fields) =>
                        fields.map((field, index) => (
                            // biome-ignore lint/correctness/useJsxKeyInIterable: can be removed when we switch form components
                            <Form.Item
                                label={index === 0 ? t('FirstName') : ' '}
                                style={{
                                    marginBottom: index === teachers.length - 1 ? undefined : '5px'
                                }}
                                {...field}
                            >
                                <Input />
                            </Form.Item>
                        ))
                    }
                </Form.List>
                <Form.List name="lastNames">
                    {(fields) =>
                        fields.map((field, index) => (
                            // biome-ignore lint/correctness/useJsxKeyInIterable: can be removed when we switch form components
                            <Form.Item
                                label={index === 0 ? t('LastName') : ' '}
                                style={{
                                    marginBottom: index === teachers.length - 1 ? undefined : '5px'
                                }}
                                {...field}
                            >
                                <Input />
                            </Form.Item>
                        ))
                    }
                </Form.List>

                <Form.List name="workPercentages">
                    {(fields) =>
                        fields.map((field, index) => (
                            // biome-ignore lint/correctness/useJsxKeyInIterable: can be removed when we switch form components
                            <Form.Item
                                label={index === 0 ? t('Employment') : ' '}
                                style={{
                                    marginBottom: index === teachers.length - 1 ? undefined : '5px'
                                }}
                                {...field}
                            >
                                <InputNumber />
                            </Form.Item>
                        ))
                    }
                </Form.List>

                <Form.Item wrapperCol={{ offset: 8 }}>
                    <Button variant="primary" type="submit">
                        {t('Save')}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default TeacherDetails
