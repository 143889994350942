import { AppError } from './errorutil'

export function nullToUndefined<T>(val: T | undefined | null): T | undefined {
    return val === null ? undefined : val
}

export function notUndefined<T>(val: T | undefined): val is T {
    return val !== undefined
}

export function notNull<T>(val: T | undefined): val is T {
    return val !== null
}

export function neitherNullNorUndefined<T>(val: T | undefined | null): val is T {
    return notUndefined(val) && notNull(val)
}

export function toTranslate(str: string): string {
    return str
}

export function throwOnUndefined<T>(val: T | undefined, errMsg: string): T {
    if (val === undefined) {
        throw new AppError(errMsg)
    }
    return val
}

function* freshNameGenerator(initialName: string) {
    yield initialName
    let i = 2
    while (true) {
        yield `${initialName} (${i++})`
    }
}

export function generateFreshName(initialName: string, existingNames: string[]) {
    const candidateNameGenerator = freshNameGenerator(initialName)
    let candidateName
    do {
        candidateName = candidateNameGenerator.next().value!
    } while (existingNames.includes(candidateName))
    return candidateName
}
