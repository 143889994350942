import { Table } from 'antd'
import classNames from 'classnames'
import type { IProblem, IStudentGroupConflictProblem } from 'common-api'
import { groupBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useSchedule } from '../../../../store/schedule/hooks'
import { combinedCmpFn, comparing } from '../../../../utils/compareUtil'
import { isBlocker, problemSeverityComparator } from '../../../../utils/problems'
import { overlapPath } from '../../../../utils/scheduleUtils'
import { problemMessageText, problemTypeLabel, teacherLabels, useClassLabels } from './ProblemUtils'
import { BarSummary, ErrorIcon, PercentageBar, WarningIcon } from './styled'
import type { ProblemListProps } from './types'
import { isVirtualProblem } from './utils'
import { useDevModeState } from '../../../../store/devmode/hooks'

const NO_GROUP_KEY = ''

export const ProblemsList = ({
    border = false,
    initialGroupExpandedState,
    problemGrouping = 'NONE',
    problems
}: ProblemListProps) => {
    const devMode = useDevModeState()
    const schedule = useSchedule()
    const { t } = useTranslation()
    const { getClassLabels } = useClassLabels()

    const problemGroupingFunctions = {
        NONE: () => [NO_GROUP_KEY],
        PROBLEM_TYPE: (p: IProblem) => [problemTypeLabel(p, t)],
        KLASSES: (p: IProblem) => getClassLabels(p, schedule),
        TEACHERS: (p: IProblem) => teacherLabels(p, schedule)
    }
    const problemGroupFunction = problemGroupingFunctions[problemGrouping]

    const problemItems = problems
        .filter((pws) => devMode || !isVirtualProblem(pws.problem))
        .sort(problemSeverityComparator)
        .flatMap((problemWithScore, i) =>
            problemGroupFunction(problemWithScore.problem).map((problemGroup, j) => ({
                key: `${i}/${j}`,
                group: problemGroup,
                isBlocker: isBlocker(problemWithScore),
                severity: isBlocker(problemWithScore) ? <ErrorIcon /> : <WarningIcon />,
                message: problemMessageText(schedule, problemWithScore.problem, t),
                problem: problemWithScore.problem
            }))
        )

    const problemItemGroups = groupBy(problemItems, (item) => item.group)

    const columns = [
        {
            title: 'W/E',
            dataIndex: 'severity',
            key: 'severity',
            width: '1.5em'
        },
        {
            title: 'Description',
            dataIndex: 'message',
            key: 'message',
            ellipsis: true
        }
    ]

    if (problemGrouping === 'NONE') {
        return (
            <Table
                className={classNames('problem-list', border || 'without-border')}
                size="small"
                dataSource={problemItemGroups[NO_GROUP_KEY]}
                columns={columns}
                showHeader={false}
                pagination={false}
            />
        )
    }

    const maxProblemCount = Object.values(problemItemGroups)
        .map((problemItems) => problemItems.length)
        .reduce((prev, current) => Math.max(prev, current), 0)

    const problemsGroupedByType = Object.entries(problemItemGroups).sort(
        combinedCmpFn(
            comparing<[string, any[]]>(([group, problems]) => -problems.filter((p) => p.isBlocker).length),
            comparing<[string, any[]]>(([group, problems]) => -problems.filter((p) => !p.isBlocker).length)
        )
    )
    return (
        <>
            {problemsGroupedByType.map(([group, problems]) => (
                <details key={group} {...(initialGroupExpandedState === 'EXPANDED' ? { open: true } : {})}>
                    <BarSummary>
                        <PercentageBar
                            fillFactor={problemItemGroups[group].filter((p) => p.isBlocker).length / maxProblemCount}
                            color="#febabf"
                            zIndex={-1}
                        />
                        <PercentageBar
                            fillFactor={problemItemGroups[group].length / maxProblemCount}
                            color="#feecba"
                            zIndex={-2}
                        />
                        <div style={{ position: 'absolute', right: '.5em', top: '0.4em' }}>
                            {problemItemGroups[group].length}
                        </div>
                        {group}
                    </BarSummary>
                    <Table
                        className={classNames('problem-list', border || 'without-border')}
                        size="small"
                        dataSource={problemItemGroups[group]}
                        columns={columns}
                        showHeader={false}
                        pagination={false}
                        onRow={(record, rowIndex) => ({
                            onClick: (event) => {
                                if (record.problem.type === 'studentGroupConflictProblem') {
                                    const sgcp = record.problem
                                        .studentGroupConflictProblem as IStudentGroupConflictProblem
                                    console.log(
                                        'Overlap path: ',
                                        (
                                            overlapPath(
                                                schedule
                                                    .findLecture(sgcp.lectureId1)
                                                    .getCourseRound()
                                                    .getStudentGroup(),
                                                schedule.findLecture(sgcp.lectureId2).getCourseRound().getStudentGroup()
                                            ) || []
                                        ).map((sg) => sg.getDisplayName())
                                    )
                                }
                            }
                        })}
                    />
                </details>
            ))}
        </>
    )
}
