import type { CourseRoundId } from '../../commonTypes'
import { colorByType } from '../../pages/schedule/components/util'
import { useLocalSchedule } from '../../store/schedule/hooks'
import brandColors from '../../styles/colors/brandColors'
import styled from '../../utils/styled'

interface CourseRoundProps {
    courseRoundId: CourseRoundId
}

export const CourseRoundCard = (props: CourseRoundProps) => {
    const schedule = useLocalSchedule()
    const courseRound = schedule.findCourseRound(props.courseRoundId)
    return (
        <Card color={colorByType(courseRound.getSubject().getCode())}>
            <div>
                <strong>{courseRound.getDisplayName()}</strong>
                <div>{courseRound.getTotalHours()}h</div>
                <div>{courseRound.getCourse()?.getCode() || courseRound.getSubject().getCode()}</div>
            </div>
        </Card>
    )
}

interface CardProps {
    color: string
}

export const Card = styled('div')<CardProps>`
    background: ${(props) => props.color};
    border: 1px solid ${brandColors.gray50};
    overflow: hidden;

    height: 100%;
    font-size: 0.7em;
    padding: 0.2em;
`
