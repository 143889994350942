import { Space } from 'antd'
import type { IEventGroup, IProblemWithScore, IReservedTime, IWeekSelection } from 'common-api'
import { IScheduleTransform } from 'common-api'
import { groupBy } from 'lodash'
import { useMemo, useRef } from 'react'
import { useDrop } from 'react-dnd'
import { useDispatch, useSelector } from 'react-redux'
import type { ApplicationState } from '../../../../store'
import { endDragLecture, locallyTriggeredScheduleTransform } from '../../../../store/schedule/actions'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import type { ScheduleSelector } from '../../../../store/scheduleselector/types'
import { DndItemTypes } from '../../../../utils/DndItemTypes'
import { relatedDayAndWeeks } from '../../../../utils/problems'
import type { VersionedScheduleTransform } from '../../../../utils/scheduleTransforms'
import { transformedEventIds } from '../../../../utils/scheduleTransforms'
import { isNonLectureProblemAmongSelectedSchedules } from '../../../../utils/scheduleUtils'
import { useNameOfDay } from '../../../../utils/weekUtil'
import { CreateBlockLayer } from '../CreateBlockLayer'
import { DragLayer } from '../DragLayer/DragLayer'
import EventComponent from '../EventComponent'
import { ProblemCorner } from '../ProblemCorner'
import type { ScheduleEvent } from '../ScheduleEvent'
import { isLectureEvent, LectureEvent, ReservedTimeEvent } from '../ScheduleEvent'
import { TimeLine } from '../TimeLine'
import {
    CreateBlockLayerWrapper,
    Day,
    DayHeader,
    DaysWrapper,
    DragLayerWrapper,
    Lectures,
    TimeLineWrapper,
    UnallocatedLecturesBackground,
    WhiteBackdrop,
    Wrapper
} from '../styled'
import type { EventAndLayoutSpec } from '../types'
import { BlockedTimeSelectionZIndex } from '../types'
import { DAYS, layoutAllocatedEvents, layoutUnallocatedEvents, positionToLayoutSpec, VISIBLE_DAYS } from '../util'
import { hasNonEmptyIntersection, isEventAmongSelectedSchedules } from './utils'
import { WeekSelectionAccessor } from '../../../../schedule-access/scheduleAccessWrappers'

const dayBucket = (event: ScheduleEvent) => {
    const dat = event.getDayAndTime()
    return dat?.day || 'UNALLOCATED'
}

export const Schedule = () => {
    const { getDayName } = useNameOfDay()
    const dispatch = useDispatch()
    const blockedTimeSelectionModeActive = useSelector<ApplicationState, boolean>(
        (state) => state.blockedTimeSelection.selectionModeActive
    )

    const pendingTransforms = useSelector<ApplicationState, VersionedScheduleTransform[]>(
        (state) => state.schedule.pendingTransforms
    )
    const weekSelection = useSelector<ApplicationState, IWeekSelection>((state) => state.weekSelection)
    const selectedSchedules = useSelector<ApplicationState, ScheduleSelector[]>(
        (state) => state.scheduleSelection.selectedSchedules
    )
    const scheduleToRender = useLocalSchedule()
    const selectedWeeks = new WeekSelectionAccessor(scheduleToRender, weekSelection).getWeeks().map((w) => w.valueOf())

    const startOfDay = scheduleToRender.getSettings().startOfDay!
    const endOfDay = scheduleToRender.getSettings().endOfDay!

    const dropWrapper = useRef<HTMLDivElement>(null)

    const [, drop] = useDrop(
        () => ({
            accept: DndItemTypes.EVENT,
            drop: (item: any, monitor) => {
                const currentOffset = monitor.getClientOffset()
                const initialOffset = monitor.getInitialClientOffset()

                if (dropWrapper.current && currentOffset && initialOffset) {
                    const layoutSpec = positionToLayoutSpec(
                        startOfDay,
                        endOfDay,
                        currentOffset,
                        dropWrapper.current.getBoundingClientRect(),
                        initialOffset,
                        item.layoutSpec
                    )

                    const scheduleEvents = item.event satisfies ScheduleEvent

                    // This code is not very elegant as of now. If we start dragging a lecture, then all N lectures in
                    // the corresponding event group will be dragged. When the lectures are dropped, this code will
                    // create a bulk transform that updates the same event group N times. We will most likely want to
                    // refactor the code so that it is the event group itself that's represents the dragged item.
                    const transforms = scheduleEvents.flatMap((scheduleEvent: ScheduleEvent) =>
                        scheduleEvent.accept<IScheduleTransform[]>({
                            visitLecture: (lectureEvent: LectureEvent) => {
                                const eventGroup = lectureEvent.getLecture().getEventGroup()

                                const newEventGroup: IEventGroup = {
                                    ...eventGroup.getConjureObject(),
                                    dayAndTime: layoutSpec?.dayAndTime || null
                                }
                                return [IScheduleTransform.eventGroupTransform({ newEventGroup })]
                            },
                            visitReservedTime: (reservedTimeEvent: ReservedTimeEvent) => {
                                if (!layoutSpec) {
                                    console.log(
                                        'Reserved time dropped outside of schedule. ' +
                                            'Should we support this as a way of removing the reserved time? ' +
                                            'Doing nothing for now.'
                                    )
                                    return []
                                }
                                const newReservedTime: IReservedTime = {
                                    ...reservedTimeEvent.getReservedTime().getConjureObject(),
                                    dayAndTime: layoutSpec.dayAndTime
                                }
                                return [
                                    IScheduleTransform.reservedTimeTransform({
                                        newReservedTime
                                    })
                                ]
                            }
                        })
                    )

                    dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(transforms)))
                    dispatch(endDragLecture())
                }
            }
        }),
        [startOfDay, endOfDay]
    )

    const allEvents: ScheduleEvent[] = [
        ...scheduleToRender.getLectures().map((l) => new LectureEvent(l)),
        ...scheduleToRender.getReservedTimes().map((rt) => new ReservedTimeEvent(rt))
    ]
    const eventsToShow = allEvents
        .filter((e) => hasNonEmptyIntersection(e.getWeekSelection().getWeeks(), selectedWeeks))
        .filter((e) => isEventAmongSelectedSchedules(scheduleToRender, e, selectedSchedules))

    const laidOutEvents = useMemo(() => {
        const dayGroups = groupBy(eventsToShow, dayBucket)
        return Object.keys(dayGroups).flatMap((day) => {
            const events = dayGroups[day]
            return day === 'UNALLOCATED'
                ? layoutUnallocatedEvents(events.filter(isLectureEvent))
                : layoutAllocatedEvents(events)
        }) satisfies EventAndLayoutSpec[]
    }, [eventsToShow])

    const sortedLaidOutEvents = useMemo(
        () => laidOutEvents.sort(({ event: l1 }, { event: l2 }) => l1.getUniqueId().localeCompare(l2.getUniqueId())),
        [laidOutEvents]
    )

    const locallyModifiedEventIds = pendingTransforms.flatMap(transformedEventIds)

    const shouldBeAnimatedIntoPosition = (event: ScheduleEvent) =>
        locallyModifiedEventIds.includes(
            event.accept({
                visitLecture: (lectureEvent) => lectureEvent.getLecture().getLectureId(),
                visitReservedTime: (reservedTimeEvent) => reservedTimeEvent.getReservedTime().getReservedTimeId()
            })
        )

    type DayToProblemsDict = { [day: string]: IProblemWithScore[] }
    const emptyDaysDict = DAYS.reduce((prev, currentValue) => ({ ...prev, [currentValue]: [] }), {})
    const nonLectureProblemsInSelectedSchedules = scheduleToRender
        .getSchedulingProblems()
        .filter((pws) => isNonLectureProblemAmongSelectedSchedules(scheduleToRender, pws.problem, selectedSchedules))

    const dayLevelProblems: DayToProblemsDict = nonLectureProblemsInSelectedSchedules
        .map((pws) => ({ pws, relatedDaws: relatedDayAndWeeks(pws.problem) }))
        .filter(({ relatedDaws }) => relatedDaws !== undefined)
        .filter(({ relatedDaws }) => hasNonEmptyIntersection(relatedDaws!.weeks, selectedWeeks))
        .map(({ pws, relatedDaws }) => ({ pws, day: relatedDaws!.day }))
        .reduce(
            (prev: DayToProblemsDict, { pws, day }) => ({
                ...prev,
                [day]: [...prev[day], pws]
            }),
            emptyDaysDict
        )

    return (
        <Space size="large" direction="vertical" style={{ width: '100%' }}>
            <Wrapper ref={drop}>
                <WhiteBackdrop style={{ zIndex: BlockedTimeSelectionZIndex.WHITE_BACKGDROP }} />
                <TimeLineWrapper style={{ zIndex: BlockedTimeSelectionZIndex.SCHEDULE_CONTENT }}>
                    <TimeLine />
                </TimeLineWrapper>
                <DaysWrapper style={{ zIndex: BlockedTimeSelectionZIndex.SCHEDULE_CONTENT }}>
                    {VISIBLE_DAYS.map((day) => (
                        <Day key={day} day={day}>
                            <DayHeader>
                                <ProblemCorner problems={dayLevelProblems[day]} />
                                {getDayName(day)}
                            </DayHeader>
                        </Day>
                    ))}
                </DaysWrapper>
                <Lectures style={{ zIndex: BlockedTimeSelectionZIndex.SCHEDULE_CONTENT }}>
                    {sortedLaidOutEvents.map(({ event, layoutSpec }) => (
                        <EventComponent
                            transition={shouldBeAnimatedIntoPosition(event) ? 'all 0s ease 0s' : undefined}
                            key={event.getUniqueId()}
                            event={event}
                            layoutSpec={layoutSpec}
                        />
                    ))}
                </Lectures>
                {blockedTimeSelectionModeActive && (
                    <CreateBlockLayerWrapper>
                        <CreateBlockLayer />
                    </CreateBlockLayerWrapper>
                )}
                <DragLayerWrapper ref={dropWrapper}>
                    <DragLayer />
                </DragLayerWrapper>
                <UnallocatedLecturesBackground />
            </Wrapper>
        </Space>
    )
}
