import { isEqual, uniq } from 'lodash'
import { AppError } from './errorutil'
import type { Optional } from './optionals'

export function replaceOrThrow<E, K>(arr: E[], keyFn: (element: E) => K, replacement: E): E[] {
    const soughtKey = keyFn(replacement)
    return updateOrThrow(
        arr,
        (e) => keyFn(e) === soughtKey,
        () => replacement
    )
}

export function multimapKeysWithValue<K, V>(map: Map<K, V[]>, v: V): K[] {
    const result = []
    for (const [k, vals] of map) {
        if (vals.includes(v)) {
            result.push(k)
        }
    }
    return result
}

export function transformMapValues<K, V>(map: Map<K, V>, valueTransform: (currentVal: V) => V): Map<K, V> {
    const transformedMap = new Map<K, V>()
    for (const [k, v] of map) {
        transformedMap.set(k, valueTransform(v))
    }
    return transformedMap
}

export function listToOptionalOrThrow<T>(arr: T[]): Optional<T> {
    if (arr.length > 1) {
        throw new AppError('Expected at most 1 element in list')
    }
    return arr.length === 0 ? undefined : arr[0]
}

export function togglePresence<T>(arr: T[], toToggle: T) {
    let found = false
    const result = []
    for (const e of arr) {
        if (isEqual(e, toToggle)) {
            found = true
        } else {
            result.push(e)
        }
    }
    if (!found) {
        result.push(toToggle)
    }
    return result
}

/**
 * Find an element based on a predicate. (Throw if element is not found.) Return a copy of arr with element
 * updated.
 *
 * @param arr the array to update
 * @param predicate the predicate used to find the element to update
 * @param updateFunction the function used to update the element
 */
export function updateOrThrow<E>(arr: E[], predicate: (element: E) => boolean, updateFunction: (element: E) => E): E[] {
    const index = arr.findIndex(predicate)
    if (index === -1) {
        throw new AppError('Could not find element to update.', { arr })
    }
    return arr.toSpliced(index, 1, updateFunction(arr[index]))
}

export const findOrThrow = <T, K>(arr: T[] | undefined, key: K, keyFn: (elem: T) => K): T => {
    const elem = (arr || []).find((elem) => keyFn(elem) === key)
    if (elem === undefined) {
        throw new AppError('Could not find element', { key, arr })
    }
    return elem
}

export const orThrow = <T>(o: T | undefined | null): T => {
    if (o === undefined || o === null) {
        throw new AppError('Expected value to be present')
    }
    return o
}

export const containsAll = <E>(sup: E[], sub: E[]) => sub.every((e) => sup.includes(e))

export const setPresence = <T>(arr: T[], shouldBePresent: boolean, elementsToSetPresenceOf: T[]) => {
    return shouldBePresent
        ? uniq([...arr, ...elementsToSetPresenceOf])
        : arr.filter((e) => !elementsToSetPresenceOf.includes(e))
}

export const indexBy = <E, T>(arr: E[], keyFn: (e: E) => T) => {
    const result = new Map<T, E[]>()
    for (let e of arr) {
        const key = keyFn(e)
        result.set(key, [...(result.get(key) ?? []), e])
    }
    return result
}

export const indexUniquelyBy = <E, T>(arr: E[], keyFn: (e: E) => T) => new Map<T, E>(arr.map((e) => [keyFn(e), e]))
