import { Space } from 'antd'
import type { TeacherId } from '../../commonTypes'
import { DeleteTeachersButton } from './components/DeleteTeacherButton'

type TeacherActionsPanelProps = {
    teacherIds: TeacherId[]
    onDelete: () => void
}

export const TeacherActionsPanel = (props: TeacherActionsPanelProps) => {
    return (
        <Space direction="vertical">
            <DeleteTeachersButton teacherIds={props.teacherIds} onDelete={props.onDelete} />
        </Space>
    )
}
