import { ConjureErrorType, isConjureError } from 'conjure-client'
import type { IAuthenticationClaims, IQueryAuthenticationServiceGetAuthenticationClaimsResponse } from 'meitner-api'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import { Endpoints } from '../../services/Endpoints'
import {
    userAuthenticated,
    userAuthenticatedWithoutOrganization,
    userAuthenticatedWithoutSchedulerRole
} from './actions'
import { SCHEDULER_ROLE } from './constants'
import { AuthActionTypes } from './types'

function redirectToLoginPage() {
    const ssoUrl = process.env.REACT_APP_MEITNER_SSO_URL
    const postLoginRedirectUrl = window.location.href
    console.log(`[login saga] Redirecting to login page. SSO URL: ${ssoUrl}, post login URL: ${postLoginRedirectUrl}`)
    window.location.replace(`${ssoUrl}/?redirect=${postLoginRedirectUrl}`)
}

function* handleInitLogin(): any {
    console.log('[login saga] initializing login procedure')

    // Query current claims
    let claims: IAuthenticationClaims
    try {
        console.log('[login saga] querying auth claims...')
        const result: IQueryAuthenticationServiceGetAuthenticationClaimsResponse = yield call(
            [Endpoints.meitnerApi, Endpoints.meitnerApi.queryAuthenticationServiceGetAuthenticationClaims],
            {}
        ) as IQueryAuthenticationServiceGetAuthenticationClaimsResponse
        console.log('[login saga] auth claims response: ', JSON.stringify(result))
        claims = result.claims as IAuthenticationClaims
    } catch (error: any) {
        if (isConjureError(error) && error.type === ConjureErrorType.Status && [302, 401].includes(error.status!)) {
            // Unauthorized response
            redirectToLoginPage()
            return
        } else {
            // Error not related to this part of the code.
            throw error
        }
    }

    // Check if claims are invalid
    if (!claims) {
        console.log('[login saga] no valid claims')
        redirectToLoginPage()
        return
    }

    // Check if user has an organization (if a profile has been selected)
    if (!claims.organization) {
        console.log('[login saga] no organization set')
        yield put(userAuthenticatedWithoutOrganization())
        return
    }

    // Check if user is in correct role. Note: This is done as a courtesy to the user (we want to show something like
    // "you don't have the correct role, talk to your administrator"). The backend performs the actual security checks
    // regardless.
    if (!claims.organization.user_employee_roles.includes(SCHEDULER_ROLE)) {
        console.log('[login saga] user is not in SCHEDULER role')
        yield put(userAuthenticatedWithoutSchedulerRole())
        return
    }

    // Claims are valid
    yield put(userAuthenticated(claims.organization.active_organization_id))
}

function* watchInitLogin() {
    yield takeLatest(AuthActionTypes.INIT_LOGIN, handleInitLogin)
}

function* authSaga() {
    yield all([fork(watchInitLogin)])
}

export default authSaga
