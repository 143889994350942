import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import { Endpoints } from '../../services/Endpoints'
import type { ApplicationState } from '../index'
import { setAutoSchedulerState } from './actions'
import { AutoSchedulerActionTypes, AutoSchedulerRunningState } from './types'
import { setAutoSchedulerSseConnectionState } from '../devmode/actions'
import { sseConnectDisconnectLoop } from '../../utils/sse/sse'

const apiBaseUrl = process.env.REACT_APP_API_URL ?? 'http://localhost:3000/api'

type SetAutoSchedulerStateAction = {
    type: typeof AutoSchedulerActionTypes.HANDLE_LOCALLY_TRIGGERED_AUTO_SCHEDULER_STATE_UPDATE
    payload: boolean
}

function* handleLocallyTriggeredAutoSchedulerStateUpdate(action: SetAutoSchedulerStateAction): any {
    const isRunning = action.payload

    // Set unknown state right after the click.
    yield put(setAutoSchedulerState(AutoSchedulerRunningState.UNKNOWN))

    // Fire off update request.
    const scheduleId = yield select((state: ApplicationState) => state.schedule.schedule.getScheduleId())
    const result = yield call(
        [Endpoints.autoSchedulingService, Endpoints.autoSchedulingService.setAutoSchedulerState],
        scheduleId,
        { isRunning }
    )

    // Set state to result of request
    yield put(
        setAutoSchedulerState(result.isRunning ? AutoSchedulerRunningState.RUNNING : AutoSchedulerRunningState.PAUSED)
    )
}

function* processAutoSchedulerStateUpdateEvent(action: any): any {
    const isRunning = action.data === 'true'
    yield put(setAutoSchedulerState(isRunning ? AutoSchedulerRunningState.RUNNING : AutoSchedulerRunningState.PAUSED))
}

function* autoSchedulerStateSubscriptionsSaga(): any {
    yield call(
        sseConnectDisconnectLoop,
        [AutoSchedulerActionTypes.SUBSCRIBE, AutoSchedulerActionTypes.UNSUBSCRIBE],
        (scheduleId: string) => `${apiBaseUrl}/auto-scheduler/${scheduleId}/updates`,
        'autoscheduler-state-update',
        (s: ApplicationState) => s.autoSchedulerState.subscriptionCount,
        processAutoSchedulerStateUpdateEvent,
        setAutoSchedulerSseConnectionState
    )
}

function* watchAutoSchedulerStateChanges(): any {
    yield takeEvery(
        AutoSchedulerActionTypes.HANDLE_LOCALLY_TRIGGERED_AUTO_SCHEDULER_STATE_UPDATE,
        handleLocallyTriggeredAutoSchedulerStateUpdate
    )
}

function* autoSchedulerSaga() {
    yield all([fork(watchAutoSchedulerStateChanges), fork(autoSchedulerStateSubscriptionsSaga)])
}

export default autoSchedulerSaga
