import { message } from 'antd'
import styled from '../../utils/styled'
import { toTranslate } from '../../utils/miscUtil'
import { Space, Spin, UploadFile } from 'antd'
import Dragger from 'antd/es/upload/Dragger'
import Icon from '@ant-design/icons'
import SvgFileRoyalSchedule from '../../icons/SvgFileRoyalSchedule'
import { Endpoints } from '../../services/Endpoints'
import { useDispatch } from 'react-redux'
import { UploadChangeParam } from 'antd/es/upload'
import { useState } from 'react'
import { UploadFileStatus } from 'antd/es/upload/interface'
import { newScheduleSelectorsLoadedDueToScheduleUpdate } from '../../store/scheduleselector/actions'
import { useLocalSchedule } from '../../store/schedule/hooks'

export const RoyalScheduleImportIndexPage = () => {
    const schedule = useLocalSchedule()
    const dispatch = useDispatch()
    const [fileUploadStatus, setFileUploadStatus] = useState<UploadFileStatus | undefined>(undefined)

    const handleOnChange = (info: UploadChangeParam<UploadFile>) => {
        setFileUploadStatus(info.file.status)
        if (info.file.status === 'done') {
            message.success(toTranslate('Schema importerad.'))
        } else if (info.file.status === 'error') {
            message.error(toTranslate('Schemaimport misslyckades. Vänligen kontakta Meitner Support.'))
        }
        dispatch(newScheduleSelectorsLoadedDueToScheduleUpdate(schedule.getScheduleId(), []))
    }

    return (
        <PageContent style={{ height: '100vh', padding: '20px' }}>
            <Space direction="vertical" size="large">
                <h1>{toTranslate('Import från RoyalSchedule')}</h1>

                <Dragger
                    disabled={fileUploadStatus === 'uploading'}
                    name="file"
                    action={`${Endpoints.API_URL}/rs-upload`}
                    onChange={handleOnChange}
                    onDrop={(e) => console.log('onDrop event: ', e)}
                    showUploadList={false}
                    withCredentials
                >
                    <p className="ant-upload-drag-icon">
                        <Icon component={SvgFileRoyalSchedule} />
                    </p>
                    <p className="ant-upload-text">Klicka här för att välja RoyalSchedule-fil att ladda upp</p>
                    <p className="ant-upload-hint">Den importerade filen kommer ersätta all data i aktuellt schema.</p>
                </Dragger>
                {fileUploadStatus === 'uploading' ? (
                    <Space>
                        <Spin /> Importerar fil...
                    </Space>
                ) : null}
            </Space>
        </PageContent>
    )
}

const PageContent = styled('article')`
    display: flex;
    column-gap: 20px;
`
