import styled from '@emotion/styled'
import type * as api from 'common-api'
import brandColors from '../../../styles/colors/brandColors'
import { ALLOCATED_PERCENT, DAY_WIDTH_PERCENT, positioningDayLeft } from './util'

const SCHEDULE_HEIGHT = '895px'
const DAY_HEADER_HEIGHT = '35px'
const TIMELINE_WIDTH = '40px'

const Wrapper = styled('div')`
    position: relative;
    height: ${SCHEDULE_HEIGHT};
`

const DaysWrapper = styled('div')`
    position: absolute;
    top: 0px;
    left: ${TIMELINE_WIDTH};
    width: calc(100% - ${TIMELINE_WIDTH});
    height: calc(${ALLOCATED_PERCENT}% + ${DAY_HEADER_HEIGHT} * (100 - ${ALLOCATED_PERCENT}) / 100);
    background-color: ${brandColors.white};
`

interface DayProps {
    day: api.DayOfWeek
}

const Day = styled('div')<DayProps>`
    position: absolute;
    top: 0px;
    left: ${(props) => positioningDayLeft(props.day)}%;
    width: ${DAY_WIDTH_PERCENT}%;
    height: 100%;
    outline: 1px solid ${brandColors.gray2};
    border: 1px solid ${brandColors.gray2};
`

const DayHeader = styled('div')`
    width: 100%;
    height: ${DAY_HEADER_HEIGHT};
    text-align: center;
    font-weight: bold;
    background: ${brandColors.white};
    display: flex;
    justify-content: center;
    align-items: center;
`

const TimeLineWrapper = styled('div')`
    position: absolute;
    top: ${DAY_HEADER_HEIGHT};
    width: 100%;
    text-align: center;
    height: calc(100% - ${DAY_HEADER_HEIGHT});
`

const Lectures = styled('div')`
    position: absolute;
    top: ${DAY_HEADER_HEIGHT};
    left: ${TIMELINE_WIDTH};
    height: calc(100% - ${DAY_HEADER_HEIGHT});
    width: calc(100% - ${TIMELINE_WIDTH});
`

const DragLayerWrapper = styled('div')`
    position: absolute;
    pointer-events: none;
    z-index: 100;
    top: ${DAY_HEADER_HEIGHT};
    left: ${TIMELINE_WIDTH};
    height: calc(100% - ${DAY_HEADER_HEIGHT});
    width: calc(100% - ${TIMELINE_WIDTH});
`

const CreateBlockLayerWrapper = styled('div')`
    position: absolute;
    z-index: 100;
    top: ${DAY_HEADER_HEIGHT};
    left: ${TIMELINE_WIDTH};
    height: calc(100% - ${DAY_HEADER_HEIGHT});
    width: calc(100% - ${TIMELINE_WIDTH});
`

const UnallocatedLecturesBackground = styled('div')`
    pointer-events: none;
    position: absolute;
    top: ${ALLOCATED_PERCENT}%;
    left: ${TIMELINE_WIDTH};
    width: calc(100% - ${TIMELINE_WIDTH});
    height: height(100% - ${ALLOCATED_PERCENT}%);
`

const WhiteBackdrop = styled.div`
    position: absolute;
    width: calc(100% + 1em);
    height: calc(${ALLOCATED_PERCENT}% + ${DAY_HEADER_HEIGHT} * (100 - ${ALLOCATED_PERCENT}) / 100 + 1em);
    top: -0.5em;
    left: -0.5em;
    background: white;
`

export {
    CreateBlockLayerWrapper,
    Day,
    DayHeader,
    DaysWrapper,
    DragLayerWrapper,
    Lectures,
    TimeLineWrapper,
    UnallocatedLecturesBackground,
    WhiteBackdrop,
    Wrapper
}
