import { Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import type { ApplicationState } from '../../../../store'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import { userAddedScheduleSelector, userRemovedScheduleSelector } from '../../../../store/scheduleselector/actions'
import type { ScheduleSelectionState } from '../../../../store/scheduleselector/types'
import { visitScheduleSelector } from '../../../../store/scheduleselector/types'
import { comparing } from '../../../../utils/compareUtil'
import { toTranslate } from '../../../../utils/miscUtil'
import { teacherDisplayName } from '../../../../utils/scheduleUtils'
import { isClassStudentGroup } from '../../../../utils/studentGroupUtil'
import {
    courseRoundOptionValue,
    optionFilter,
    roomOptionValue,
    studentGroupOptionValue,
    teacherOptionValue
} from './utils'

export const ScheduleSearchSelector = () => {
    const dispatch = useDispatch()
    const schedule = useLocalSchedule()
    const scheduleSelection = useSelector<ApplicationState, ScheduleSelectionState>((state) => state.scheduleSelection)

    const { t } = useTranslation()

    const teacherOptions = schedule
        .getTeachers()
        .map((t) => ({
            value: teacherOptionValue(t.getTeacherId()),
            label: teacherDisplayName(t),
            selector: { teacherId: t.getTeacherId() }
        }))
        .sort(comparing((opt) => opt.label))

    const studentGroupOptions = schedule
        .getStudentGroups()
        .filter(isClassStudentGroup)
        .map((sg) => ({
            value: studentGroupOptionValue(sg.getStudentGroupId()),
            label: sg.getDisplayName(),
            selector: { studentGroupId: sg.getStudentGroupId() }
        }))
        .sort(comparing((opt) => opt.label))

    const courseRoundOptions = schedule
        .getCourseRounds()
        .map((cr) => ({
            value: courseRoundOptionValue(cr.getCourseRoundId()),
            label: cr.getDisplayName(),
            selector: { courseRoundId: cr.getCourseRoundId() }
        }))
        .sort(comparing((opt) => opt.label))

    const roomOptions = schedule
        .getRooms()
        .map((r) => ({
            value: roomOptionValue(r.getRoomId()),
            label: r.getName(),
            selector: { roomId: r.getRoomId() }
        }))
        .sort(comparing((opt) => opt.label))

    const allOption = {
        value: 'all',
        label: t('All'),
        selector: { markerField: true }
    }

    // Create selector lookup dict
    const allOptions = [...teacherOptions, ...studentGroupOptions, ...courseRoundOptions, ...roomOptions, allOption]
    const selectorsByOptId = Object.assign({}, ...allOptions.map((opt) => ({ [opt.value]: opt.selector })))

    const onDeselect = (optionValue: string) => dispatch(userRemovedScheduleSelector(selectorsByOptId[optionValue]))
    const onSelect = (optionValue: string) => dispatch(userAddedScheduleSelector(selectorsByOptId[optionValue]))

    const currentlySelectedValues = scheduleSelection.selectedSchedules.map((ss) => {
        return visitScheduleSelector(ss, {
            studentGroupSelector: (s) => studentGroupOptionValue(s.studentGroupId),
            teacherSelector: (s) => teacherOptionValue(s.teacherId),
            courseRoundSelector: (s) => courseRoundOptionValue(s.courseRoundId),
            roomSelector: (s) => roomOptionValue(s.roomId),
            allSelector: () => 'all'
        })
    })

    const options = [
        { label: t('Teacher'), options: teacherOptions },
        { label: toTranslate('Klasser'), options: studentGroupOptions },
        { label: toTranslate('Kursomgångar'), options: courseRoundOptions },
        { label: toTranslate('Salar'), options: roomOptions },
        { label: t('Other'), options: [allOption] }
    ]

    return (
        <Select
            mode={'multiple'}
            placeholder={t('SearchPlaceholders.SearchSchedule')}
            style={{ width: '30em' }}
            filterOption={optionFilter}
            onSelect={onSelect}
            onDeselect={onDeselect}
            value={currentlySelectedValues}
            maxTagCount={5}
            options={options}
        />
    )
}
