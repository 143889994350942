import { toTranslate } from '../utils/miscUtil'
import type { SVGProps } from 'react'

const SvgEllipsisCircle = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" className="ellipsis-circle_svg__icon" {...props}>
        <title>{toTranslate('Started')}</title>
        <defs>
            <mask id="ellipsis">
                <rect x="0" y="0" width="1024" height="1024" fill="white" />
                <circle cx="300" cy="512" r="70" fill="black" />
                <circle cx="512" cy="512" r="70" fill="black" />
                <circle cx="724" cy="512" r="70" fill="black" />
            </mask>
        </defs>
        <circle cx="512" cy="512" r="512" mask="url(#ellipsis)" />
    </svg>
)

export default SvgEllipsisCircle
